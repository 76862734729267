// @import '@angular/material/prebuilt-themes/indigo-pink.css';

/* font family variables */
$font-Helvetica: "HelveticaNow", sans-serif;
$font-Helvetica-bold: "HelveticaNow-Bold";
$font-Helvetica-thin: "HelveticaNow-thin";
$font-Helvetica-regular: "HelveticaNow-Regular";
$font-Helvetica-medium: "HelveticaNow-Medium";

$font-Sora: "Sora", sans-serif;
$font-Sora-bold: "Sora-Bold";
$font-Sora-semi-bold: "Sora-SemiBold";
$font-Sora-extra-bold: "Sora-ExtraBold";
$font-Sora-light: "Sora-Light";
$font-Sora-extra-light: "Sora-ExtraLight";
$font-Sora-medium: "Sora-Medium";
$font-Sora-regular: "Sora-Regular";

$font-manrope: "Manrope", sans-serif;
$font-bold: "Manrope-Bold";
$font-semi-bold: "Manrope-SemiBold";
$font-extra-bold: "Manrope-ExtraBold";
$font-light: "Manrope-Light";
$font-extra-light: "Manrope-ExtraLight";
$font-medium: "Manrope-Medium";
$font-regular: "Manrope-Regular";
$font-barlow-bold: "Barlow Condensed";

/* font style variables */

$font-style-normal: normal;
$font-style-italic: italic;
$font-style-oblique: oblique;

/* font weight variables */

$fw-100: 100;
$fw-200: 200;
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;

$fw-normal: normal;
$fw-bold: bold;
$fw-bolder: bolder;
$fw-lighter: lighter;

/* font size variables */
$f-10: 0.8rem;
$f-12: 0.75rem;
$f-14: 0.875rem;
$f-15: 0.938rem;
$f-16: 1rem;
$f-18: 1.125rem;
$f-20: 1.25rem;
$f-24: 1.5rem;
$f-25: 1.563rem;
$f-27: 1.688rem;
$f-32: 2rem;
$f-65: 4.063rem;
$f-28: 1.75rem;
$f-327: 20.438rem;

/* theme color variables */
$theme-bg-color-primary: #1e5af9;
$theme-color-primary: #0061f7;
$theme-color-secondary: #8693d6;
$theme-color-dark: #202c36;
$theme-color-light: #616161;
$font-color-primary: #6d6d6d;
$theme-color-popup: #0a1652;
$theme-onboard-title: #292929;
$theme-color-label: #6d6d6d;

/* color code variables */

$color-green-primary: #00740a;
$color-yellow-secondary: #f6ca00;
$color-yellow-light: #ebbc46;
$color-white: #ffffff;
$color-black: #000000;
$color-blue: #233a80;
$light-yellow: #ffe4b9;
$color-light-grey: #f9f9f9;
$color-dark-grey: #ececec;
$color-gray-light: #f8f8f8;
$color-light-cyan: #eff2f4;
$color-light-white: #f7f3f1;
$color-anti-white: #ffffffa3;
$color-brown-white: #ffffffa8;
$color-dark-white: #ffffff;
$placeholder-disabled: #f6f8f9;

$color-baby-pink: #e13057;
$color-ink-blue: #4441ff;
$color-dark-purple: #c082ff;
$color-green-shade: #34c94e;
$color-shy-blue: #43d9be;
$color-blue-transparent: #35adf2;
$color-red-shade: #f23535;
$color-orange-shade: #f27235;
$color-darkgrey: #373737;
$color-grey: #aab5ba;
$color-green: #58ac00;
$color-green-apple: #3f9d31;
$color-gray: #808080;
$color-alto: #dfdfdf;
$color-orient: #005789;
$color-mercury: #e9e9e9;
$color-moddy-blue: #6a79cc;
$color-periwinkle-gray: #747474;
$color-catskill-white: #f2f5f9;
$color-white-smoke: #f5f5fd;
$color-amaranth: #e42c5e;
$color-cinnabar: #ea4b4b;
$color-light-green: #edffea;
$color-green-haze: #aae2c9;
$color-japanese-laurel-green: #009800;
$color-lightning-yellow: #fdbe16;
$color-lemon-yellow: #ffff17;
$color-Burgundy: #aa0033;
$color-wild-sand: #f4f4f4;
$color-silver: #cccccc;
$color-cornflower-blue: #f2f5f97a;
$color-gallery: #ececec;
$color-silver-chalice: #a5a5a5;
$color-dove-gray: #707070;
$color-mercury-grey: #e2e2e2;
$color-light-red: #f8f8f8;
$color-light-grey: #f8f8f8;
$color-light-blue: #eff1fc;
$color-dark-gray: #585858;
$color-platinum: #e4e4e4;
$color-shade-of-blue: #3f51b5;
$color-ghost-white: #f2f5f9;
$color-boulder-grey: #757575;
$validate-bg: #f3f6ff;
$color-red: #ff0000;
$status-inactive-bg: #a9a9a9;
$color-sandal: #a9a9a9;
$color-light-greenish: #32cd32;
$color-granite-ray: #585858;
$color-seashell-white: #f1f1f1;
$color-alto: #d9d9d9;
$color-snow-drift: #f9faff;
$color-Bright-grey: #3f4254;
$color-muted-grey: #8a8a8a;
$color-White-Lilac: #f7f8fc;
$color-Dark-Spring-Green: #237547;
$color-light-spring-green: #559776;
$color-Yellow-ochre: #cc9500;
$color-Bleach-white: #fff4d8;
$color-Honeydew: #edffea;
$color-Amour: #ffeaea;
$color-Blue-chalk: #f5eaff;
$color-Deep-Lavender: #8455ad;
$color-titan-white: #e9ecff;
$color-pearl-red: #fcefef;
$color-purple-deep: #ff004c;
$color-purple: #e4bac73d;
$color-lavender: #f5eaff;
$color-desert-storm: #f5f8fa;
$color-spring-wood: #f6f6f6;
$color-light-grey: #f8f8f8;
$color-brown-orange: #a03400;
$color-light-sandal: #d9bfa9;
$color-light-brown: #754113;
$color-coffee-light-brown: #675b50;
$color-dark-brown: #4f3a27;
$color-lightest-grey: #e1dfdf;
$color-light-orange: #ebd7c6;
$color-light-green: #1e5af9;
$color-dark-blue: #021f76;
$color-mediumslate-blue: #3a4aa0;
$color-grey: #c5c5c566;
$color-gray-shades: #6d6d6d;
$color-grey-light: #b8b4b4;
$color-white-light: #e3e3e3;
$color-white-shades: #e0e0e0;
$color-white-green: #def6e2;
$color-white-blue: #dde3fe;
$color-medium-shade-blue: #13316e;
$color-light-shade-blue: #d6dbe9;
$color-light-green-shade: #dbf3ef;
$color-medium-shade-green: #23b59b;
$color-light-shade-sandal: #fde8de;
$color-light-pink: #eedae1;
$color-medium-shade-pink: #981e46;
$color-light-violet: #e8e3f6;
$color-medium-violet: #7255c6;
$color-light-grey-shade: #cfcfcf63;
$color-light-cyan: #6d929f;
$color-light-pink-fade: #b96060;
$color-lightest-pink-fade: #a06f6f;
$color-dark-grey-shade: #343434;
$color-bright-orange: #ff860c;
$color-light-orange: #fc742b;
$color-lightest-grey-shade: #eaeaea;
$color-darkest-grey: #8c8c8c;
$color-light-gold: #9b9368;
$color-dark-gold: #a2a640;
$color-dark-green: #00700b;
$color-darkest-red: #645959;
$color-darkest-blue: #408aa6;
$color-brown: #ac7f52;
$color-darkest-green: #3e4b3f;
$color-light-white-shade: #f5ebff;
$color-light-gray-shade: #d7d7d7;
$color-submitted: #800080;
$color-total: #729fcf;
$color-received: #9da84e;
$color-forwarded: #999999;
$color-assigned: #b48798;
$color-acknowledged: #2a6099;
$color-defered: #ff972f;
$color-completed: #355269;
$color-reviewed: #bf819e;
$color-revaluated: #c6c613;
$color-approved: #21ac46;
$color-dispatched: #5fa74c;
$color-appealed: #ff7b59;
$color-accepted: #069a2e;
$primary-bg-table-color: #da291c;
$txt-red-clr: #da291c;
$atch-txt-clr: #1e5af9;
$notification-input-txt-clr: #858585;
$notification-txt-frm: #557185;
$notification-txt-separator: #55718587;
$cont-background: #fff9ee;
$cont-success-background: #a8e4b8;
$cont-success-bg: #EFFFE6;
$rectangle-background: #fce4d6;
/* cusrsor variables */

$cursor-auto: auto;
$cursor-pointer: pointer;
$cursor-default: default;
$cursor-not-allowed: not-allowed;

/* overflow variables */

$display-none: none;
$display-block: block;
$display-flex: flex;
$display-inline-block: flex;

/* overflow variables */

$overflow-auto: auto;
$overflow-hidden: hidden;
$overflow-scroll: scroll;
$overflow-visible: visible;

/* other variables */

$outline-none: none;
$white-space-nowrap: nowrap;
$text-overflow: ellipsis;
$webkit-box-orient: vertical;
$box-shadow-none: none;

/* border variables */

$border-unset: unset;

/* z-index variables */

$z-index-100: 100;
$z-index-200: 200;
$z-index-500: 500;
$z-index-800: 800;
$z-index-1000: 1000;

/* postion variables */

$position-absolute: absolute;
$position-fixed: fixed;
$position-relative: relative;
$position-static: static;
$position-sticky: sticky;
$position-unset: unset;

/* custom variables */

/* card default properties */

$card-default-border-radius: 10px;
$card-default-box-shadow: 0px 3px 82px #0000000d;

/* dialog prompt default properties */

$dialog-default-border-radius: 17px;
$dialog-default-box-shadow: 0px 2px 7px #0000000a;

/* button default properties */

$form-action-button-default-border-radius: 8px;
$download-button-default-border-radius: 8px;
$create-button-default-border-radius: 6px;
$submit-button-default-border-radius: 5px;
$modal-button-default-border-radius: 8px;

$action-button-color: #0061f7;
$border-line-color: #ddd;
$container-line-color: #da291c;
$body-bg: #f9f8f6;
$tr-body: #dee3ef;
$ellipsis-bg: #dee3ef;

$header-bg: #ffffff;
$border-before: #dcdcdc;
$text-font-default: #000000;
$pagination-bg: #0061f7;
$fast-pagination-disabled: #e0dfdd;
$bg-white: #ffffff;
$dropdown-border: #8e8e8e;
$navbar-bg: #0b2036;
$information-bg-color: #b7f5ff;
$dropdown-bg: #0b2036;
$active-menu: #da291c;
$table-border: #dcdcdc;
$apply-btn-clr: #292929;
$entire-bg: #f9f8f6;
$table-header-bg: #e0e3e3;
$popup-border-top: #da291c;
$popup-header-bg: #f6f8fa;
$checkbox-bg: #0061f7;
$dropdown-border-clr: #d8d8d8;
$step-bg-clr: #da291c;
$menu-hover-bg: #2f4154;
$selected-step-bg: #a7f07c;
$succeed-bg: #abc740;
$text-default-red-clr: #da291c;
$text-blue-clr: #1e5af9;
$border-dashed-clr: #a5a6a6;
$stepper-completed: #a7f07c;
$edit-color: #1e5af9;
$agree-bg: #f0f6ff;
$confirmation-bg: #f7baba6b;
$user-text-clr: #292929;
$login-left-bg: #21396d;
$welcome-bg: #d92b1f;
$login-txt: #292929;
$forgot-txt: #d92b1f;
$newuser-bg: #21396d14;
$new-portal-txt: #292929;
$border-ryt-login: #d92b1f;
$defaulut-blue: #21396d;
$edit-bg: #dee3ef;
$modal-user-bg: #efefef;
$view-label-clr: #707070;
$color-paid: #d8f3da;
$color-unpaid: #f7dada;
$error-transparent-bg: #fdf4f3;
$primary-txt-clr: #1e5af9;
$comments-thin-clr: #6e6e6e;
$list-bg-clr: #292929;
$records-txt-clr: #ff5733;
$success-failed-clr: #dc5248;
$text-default-red: #d92b1f;
$contact-border-clr: #869dae;
$step-bg-disabled: #879dad;
$step-text-disabled: #879dad;
$notification-popup-shadow: #8b8b8b29;
$color-black-tp: #00000021;
$color-primary-blue: #0061f7;
$color-cancel_bg: #d63322;
$color-continue-bd: #979797;
$status-count-bg: #eff3f7;
$table-header-popup: #e8ebf3;
$status-accordion-bg: #e5e9f2;

@mixin make-container() {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

.container-custom {
  @include make-container();
  max-width: 100%;
}

@media (min-width: 1200px) and (max-width: 5120px) {
  .container-custom {
    max-width: 1590px;
  }
}

@media (min-width: 1600px) {
  .container-xxxl,
  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1300px;
  }
}
