@import "variable";

/* material form field mixin style */

/* material checkbox mixin style*/

.mdc-checkbox-checked.mat-primary .mdc-checkbox-background {
  background-color: $color-light-green !important;
}

/* material slide toggle mixin style*/

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: $color-light-green !important;
}
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: $color-white !important;
}

@mixin mat-form-field(
  $wrapper-padding-bottom: 15px,
  $form-border-radius-start: 7px 0 0 7px,
  $form-border-radius-end: 0 7px 7px 0,
  $form-field-infix-padding: 15px 0px 15px 0px,
  $form-field-infix-width: 13rem,
  $form-field-infix-height: auto,
  $form-field-input-margin-left: 0.625rem
) {
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 10.5px !important;
    border-radius: $form-border-radius-start !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-end {
    border-radius: $form-border-radius-end !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $color-dark-blue !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $color-dark-blue !important;
  }

  .mat-form-field-appearance-outline .mat-form-field-outline-thick {
    color: $color-dark-blue !important;
    // background-color: $color-white !important;
  }

  .custom-style
    .mat-form-field-appearance-outline
    .mat-form-field-outline-thick {
    color: #a1a1a1 !important;
    background-color: $color-white !important;
    border-radius: 0.5rem !important;
  }

  .custom-style .mat-mdc-text-field-wrapper {
    margin: 0 !important;
    padding: 0 !important;
  }

  .opened {
    .mat-mdc-text-field-flex,
    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      background-color: #2fa20a !important;
      color: #2fa20a !important;
      border-radius: 0.3rem !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0 !important;
    }

    .mat-select-placeholder,
    .mat-select-value {
      color: $color-white;
    }
  }

  .in-progress {
    .mat-form-field-appearance-outline .mat-form-field-outline-thick,
    .mat-mdc-text-field-flex {
      background-color: #ebbc46 !important;
      color: #ebbc46 !important;
      border-radius: 0.3rem !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0 !important;
    }
  }

  .closed {
    .mat-mdc-text-field-flex,
    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      background-color: #cb2727 !important;
      color: #cb2727 !important;
      border-radius: 0.3rem !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline {
      top: 0 !important;
    }
  }

  .custom-search
    .mat-form-field-appearance-outline
    .mat-mdc-text-field-wrapper {
    margin: 0 !important;
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field-infix {
    padding: $form-field-infix-padding !important;
    width: $form-field-infix-width !important;
    width: auto !important;
    border-top: $border-unset !important;
    height: $form-field-infix-height !important;

    input {
      margin-left: $form-field-input-margin-left !important;
    }
    input.color {
      cursor: pointer !important;
      margin-left: 0 !important;
    }
  }

  .mat-mdc-text-field-flex {
    align-items: center !important;
  }

  .mat-form-field-appearance-outline .mat-mdc-text-field-flex {
    padding: 2px 0.75em 0 0.75em !important;
  }

  .mat-form-field-appearance-outline .mdc-floating-label {
    margin-top: 0em !important;
  }

  .mat-form-field-prefix,
  .mat-form-field-suffix {
    display: contents;
  }

  //custom - input for rate field
  .input_suffix_text .mat-mdc-text-field-flex.ng-tns-c30-21 {
    padding-right: 0 !important;
  }

  .input_suffix_text .input {
    margin: 0 !important;
  }

  .input_suffix_text .mat-mdc-text-field-infix {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .custom-input input {
    margin: 0 !important;
  }

  // .input_suffix_text .mat-mdc-text-field-flex {
  //     padding: 2px 0 0rem 0 !important;
  // }

  .suffix_text
    .mat-form-field-appearance-outline
    .mat-mdc-text-field-infix
    input {
    margin-left: 0rem !important;
    padding-left: 0.75rem !important;
  }

  // .disabled-field-color .mat-form-field-appearance-outline .mat-form-field-outline-start,
  // .disabled-field-color .mat-form-field-appearance-outline .mat-form-field-outline-end {
  //     border: 1px solid $color-dark-blue !important;
  // }

  // .disabled-field-color .mat-form-field-appearance-outline .mat-form-field-outline-gap {
  //     border: 1px solid $color-dark-blue !important;
  // }

  .disabled-field-color
    .mat-form-field-appearance-outline
    .mat-mdc-text-field-wrapper
    .mat-mdc-text-field-flex
    .mat-form-field-outline {
    cursor: not-allowed !important;
    background: $color-white !important;
    opacity: 0.7;
    border-radius: 0rem;
  }

  // custom-input-phonenumber UI changes
  // .custom_phonenumber .

  .custom_phonenumber .mdc-notched-outline__leading {
    border: 0px;
  }
  .custom_phonenumber .mdc-notched-outline__trailing {
    border: 0;
  }
  .custom_phonenumber .mdc-text-field--filled:not(.mdc-text-field--disabled)
 {
    background-color: $color-white !important;
  }
  .custom_phonenumber
    .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: transparent;
  }

  .custom_phonenumber {
    &.disabled-field-color {
      background: $color-white !important;
    }
    border: 1px solid #00000059;
    border-radius: 0px;

    height: 56px;

    .mat-form-field-outline-end.ng-tns-c27-11 {
      border-radius: 0px !important;
      border: none !important;
    }

    .mat-form-field-outline-start.ng-tns-c27-12 {
      border-radius: 0px !important;
      border: none !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border: none;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      border: none !important;
    }

    .flex_1 {
      flex: 0 0 36%;
    }

    .flex_2 {
      flex: 0 0 64%;
    }

    .mat-mdc-text-field-flex.ng-tns-c27-11:hover {
      border: none !important;
      border-right: 1px solid $color-dark-blue !important;
    }

    .mat-mdc-text-field-flex.ng-tns-c27-12:hover {
      border: none !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: $color-white-shades;
    }

    .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-start,
    .custom_phonenumber
      .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-end,
    .custom_phonenumber
      .mat-form-field-appearance-outline
      .mat-form-field-outline-thick
      .mat-form-field-outline-gap {
      border-width: 1px;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      opacity: 0 !important;
      color: $color-white-shades;
    }

    .mat-form-field-appearance-outline .mat-mdc-text-field-wrapper {
      margin: 0;
      padding: 0 !important;
      height: 100%;
    }

    .mat-form-field-appearance-outline .mat-mdc-text-field-flex {
      margin-top: 0em !important;
      height: 100%;
    }

    mat-form-field:nth-child(1)
      .mat-mdc-text-field-wrapper
      .mat-mdc-text-field-flex {
      border-right: 1px solid $color-dark-blue;
      height: 2.5rem;
    }

    .mat-form-field-appearance-outline .mat-mdc-text-field-infix input {
      margin-left: 0rem !important;
    }

    .mat-form-field-appearance-outline .mat-mdc-text-field-infix {
      width: 0px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-start {
      width: 10.5px !important;
      border-radius: 0px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-end {
      border-radius: 0px !important;
    }

    .mat-form-field-appearance-outline .mat-form-field-outline-thick {
      color: none !important;
    }
  }

  .custom_phonenumber:hover
    .mat-form-field-appearance-outline
    .mat-form-field-outline-start,
  .custom_phonenumber:hover
    .mat-form-field-appearance-outline
    .mat-form-field-outline-end {
    border: none !important;
  }

  .input_autocomplete .custom_phonenumber .mat-form-field:nth-child(1) {
    flex: 0 0 30%;
  }
  .input_autocomplete .custom_phonenumber .mat-form-field:nth-child(2) {
    flex: 0 0 70%;
  }
  .input_autocomplete_suffix .custom_phonenumber .mat-form-field:nth-child(1) {
    flex: 0 0 70%;
  }
  .input_autocomplete_suffix .custom_phonenumber .mat-form-field:nth-child(2) {
    flex: 0 0 30%;
  }
  .input_autocomplete_popup .custom_phonenumber .mat-form-field:nth-child(1) {
    flex: 0 0 30%;
  }
  .input_autocomplete_popup .custom_phonenumber .mat-form-field:nth-child(2) {
    flex: 0 0 70%;
  }
}

.custom-height > mdc-dialog__container {
  overflow: hidden !important;
}

.status_history_table
  .custom-table-container
  .custom-table
  .custom-table-body
  .custom-table-body-row
  .custom-table-body-cell {
  height: 3rem !important;
}

/* font mixins */

/*  theme */

@mixin theme-color-picker($color, $background-color) {
  @if $color !=null and $background-color !=null {
    background-color: $background-color !important;
    color: $color !important;
  } @else if $color !=null {
    color: $color !important;
  } @else if $background-color !=null {
    background-color: $background-color !important;
  } @else {
    background-color: $theme-color-primary !important;
  }
}

/* paddings / margins */

@mixin padding-margin-picker($top, $bottom, $left, $right, $type) {
  @if $top !=null and $bottom !=null and $left !=null and $right !=null {
    @if $type == "padding" {
      padding: $top $right $bottom $left !important;
    } @else {
      margin: $top $right $bottom $left !important;
    }
  } @else if $top !=null and $bottom !=null {
    @if $type == "padding" {
      padding: $top 0 $bottom 0 !important;
    } @else {
      margin: $top 0 $bottom 0 !important;
    }
  } @else if $left !=null and $right !=null {
    @if $type == "padding" {
      padding: 0 $right 0 $left !important;
    } @else {
      margin: 0 $right 0 $left !important;
    }
  } @else if $top !=null {
    padding-top: $top !important;
  } @else if $bottom !=null {
    padding-bottom: $bottom !important;
  } @else if $left !=null {
    padding-left: $left !important;
  } @else if $right !=null {
    padding-right: $right !important;
  }
}
.reduce-padding {
  .table > :not(caption) > * > * {
    vertical-align: middle;
    padding: 0.2rem !important;
  }
}
.remove-padding {
  .multiselect-dropdown {
    margin: 0 !important;
    .dropdown-list {
      .item1 {
        .multiselect-item-checkbox {
          padding: 15px 10px !important;
          div {
            font-size: 0.9rem;
          }
          input[type="checkbox"] + div:before {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
      .item2 {
        .multiselect-item-checkbox {
          div {
            font-size: 0.8rem;
          }
          input[type="checkbox"] + div:before {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
    }
    .dropdown-btn {
      span {
        font-size: 0.8rem !important;
      }
    }
  }
  .mat-mdc-text-field-wrapper {
    padding: 0 !important;
    margin: 0 !important;
    ::placeholder {
      font-size: 0.8rem !important;
    }
  }
}

.category-filter {
  position: relative;
  .mdc-dialog__container {
    position: absolute !important;
    right: 2.7rem !important;
    top: 7.5rem !important;
    width: 74.5%;
    padding-bottom: 1rem;
    height: 65dvh !important;
    overflow: hidden !important;
  }
}

.chart-mat-label-bg {
  margin-top: 2rem;
  margin-left: 0.4rem;
  margin-right: 0.4rem;
  .mdc-tab-indicator {
    background-color: #e5e5e5 !important;
    border: 1px solid #e5e5e5;
  }
}

.disabled-field .clearIcn {
  display: none;
}

.mat-form-field-disabled,
input:disabled {
  background: #f6f8f9 0% 0% no-repeat padding-box !important;
}

app-custom-multi-fileupload[ng-reflect-disable-file-upload="true"]
  .file_border {
  background: #f6f8f9 0% 0% no-repeat padding-box !important;
}

app-custom-multi-fileupload[ng-reflect-disable-file-upload="true"]
  .file-input
  > .btn_choose {
  background: #c7d3dc 0% 0% no-repeat padding-box !important;
}
