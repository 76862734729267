/* application variables,mixins & other style imports */

@use "@angular/material" as mat;
@import "typography";
@import "../global/variable.scss";

html,
body {
  height: 100%;
  width: 100%;
}

body {
  margin: 0;
  font-family: $font-Helvetica-regular !important;

  .table_attachment {
    width: 100%;
    display: inline-block;
    text-align: center;
    font-size: 22px;
    color: $color-dark-blue;
  }

  .multiselect-dropdown .dropdown-btn {
    max-height: max-content !important;
  }

  .tableKeywordSearch {
    font-size: 14px !important;
  }

  .ng2-tag-input {
    border: 1px solid #e0e0e0 !important;
    border-radius: 10px !important;
    padding: 0.3rem !important;
  }
  .ng2-tag-input.ng2-tag-input--focused {
    border: 1px solid #e0e0e0 !important;
    border-radius: 10px !important;
  }

  .ng2-tag-input__text-input {
    padding: 1rem !important;
  }

  #basicDetails {
    .custom-table-container .custom-table {
      max-height: none;
    }
  }

  /* below mixin for mat form field */
  @include mat-form-field;

  /* below will remove input type number spinner */

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    appearance: unset;
    -webkit-appearance: none;
  }
}

/* below will change snack bar action label color */
.mat-simple-snackbar-action {
  color: $color-white !important;
}

/* Bubble indicators */
.bubble-section {
  .bubble {
    width: 5px !important;
    height: 2.6rem !important;
    border-radius: 0px 5px 5px 0px;
    opacity: 1;
  }
}

// background color class

.bg-color-primary {
  @include theme-color-picker(null, $theme-color-primary);
}

.bg-color-grey {
  @include theme-color-picker(null, $color-dark-gray);
}

.bg-color-secondary {
  @include theme-color-picker(null, $theme-color-secondary);
}

.bg-color-wild-sand {
  @include theme-color-picker(null, $color-light-blue);
}

.bg-color-cornflower-blue {
  @include theme-color-picker(null, $color-light-grey);
}

.bg-color-pearl-red {
  @include theme-color-picker(null, $color-pearl-red);
}

.bg-color-desert-storm {
  @include theme-color-picker(null, $color-desert-storm);
}

.bg-color-vista-white {
  @include theme-color-picker(null, $color-light-red);
}

.bg-color-white {
  @include theme-color-picker(null, $color-white);
}

.bg-color-green {
  @include theme-color-picker(null, $color-green);
}

.bg-color-accent {
  @include theme-color-picker(null, $color-lightning-yellow);
}

// color class

.color-primary {
  @include theme-color-picker($font-color-primary, null);
}

.color-secondary {
  @include theme-color-picker($theme-color-secondary, null);
}

.color-light {
  @include theme-color-picker($theme-color-light, null);
}

.color-grey {
  @include theme-color-picker($color-grey, null);
}

.color-darkgrey {
  @include theme-color-picker($color-darkgrey, null);
}

.color-amaranth {
  @include theme-color-picker($color-amaranth, null);
}

.color-green {
  @include theme-color-picker($color-green, null);
}

.color-gray {
  @include theme-color-picker($color-gray, null);
}

.color-Orient {
  @include theme-color-picker($color-dark-blue, null);
}

.color-green-haze {
  @include theme-color-picker($color-green-haze, null);
}

.color-cinnabar {
  @include theme-color-picker($color-cinnabar, null);
}

.color-boulder-grey {
  @include theme-color-picker($color-boulder-grey, null);
}

.color-red {
  @include theme-color-picker($color-red, null);
}

.color-granite-gray {
  @include theme-color-picker($color-granite-ray, null);
}

.color-Dark-Spring-Green {
  @include theme-color-picker($color-Dark-Spring-Green, null);
}

.mdc-radio.mdc-radio--checked .mat-radio-outer-circle {
  border-color: $color-dark-blue;
}

.mdc-radio.mdc-radio--checked .mat-radio-inner-circle {
  background-color: $color-dark-blue;
}

/* Application cursor properties*/

.cursor-pointer {
  cursor: $cursor-pointer !important;
}

.cursor-default {
  cursor: $cursor-default !important;
}

.cursor-not-allowed {
  cursor: $cursor-not-allowed !important;
  opacity: 0.6;
}

/* Application padding / margin properties */

.p-15 {
  @include padding-margin-picker(12px, 12px, 1.5rem, 15px, padding);
}

.p-top-bottom-15 {
  @include padding-margin-picker(12px, 12px, 0px, 0px, padding);
}

/* Width */

.w-80 {
  width: 80% !important;
}

.w-90 {
  width: 90% !important;
}

/* Application dialog properties */

.dialog-default-style {
  border-radius: $dialog-default-border-radius !important;
  box-shadow: $dialog-default-box-shadow !important;
}

.upload-default-style {
  min-height: 2.875rem !important;

  border-color: $color-green-primary !important;
  @include theme-color-picker($color-green-primary, null);
  color: $color-white !important;
  background: #f44336;
  border-radius: 4px !important;
  margin-right: 7px !important;
}

.download-default-style {
  min-height: 2.875rem !important;
  font-family: $font-semi-bold !important;
  border-color: $color-green-primary !important;
  border-radius: $download-button-default-border-radius !important;
  @include theme-color-picker($color-green-primary, null);
}

.download-default-style img {
  margin-right: 7px;
}

.create-default-style,
.create-default-style-secondary {
  height: 41px;

  min-width: 6rem !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  font-family: $font-Helvetica-medium !important;
  border-color: $theme-color-primary !important;

  @include theme-color-picker(null, $theme-color-primary);
  @include theme-color-picker($color-white, null);
}

.create-default-style-secondary {
  @include theme-color-picker(null, $theme-color-secondary);
}

.create-specific-style {
  min-width: 6rem !important;
  min-height: 2.775rem !important;
  box-shadow: none !important;
  font-family: $font-semi-bold !important;
  border-color: $color-green-primary !important;
  border-radius: $create-button-default-border-radius !important;
  @include theme-color-picker(null, $theme-color-secondary);
  @include theme-color-picker($color-black, null);
}

.submit-default-style {
  min-height: 2.813rem !important;
  min-width: 23.313rem !important;
  font-family: $font-semi-bold !important;
  border-radius: $submit-button-default-border-radius !important;
  @include theme-color-picker(null, $color-green-primary);
  @include theme-color-picker($color-white, null);
}

.register-default-style {
  min-height: 2.813rem !important;
  min-width: 13.438rem !important;
  font-family: $font-semi-bold !important;
  border-radius: $submit-button-default-border-radius !important;
  @include theme-color-picker(null, $color-green-primary);
  @include theme-color-picker($color-white, null);
}

.save-default-style {
  min-height: 2.5rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0.6rem !important;
  box-shadow: none !important;
  @include theme-color-picker(null, $color-dark-blue);
  @include theme-color-picker($color-light-white, null);
}

.scanner-default-style {
  min-height: 2.8rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  box-shadow: none !important;
  @include theme-color-picker(null, $color-light-blue);
  @include theme-color-picker($color-dark-blue, null);
  border: 1px solid $color-dark-blue !important;
  border-radius: 6px;
  opacity: 1;
}

.save-draft-default-style {
  min-height: 2.775rem !important;
  min-width: 10rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0.3rem !important;
  @include theme-color-picker(null, $color-lightest-grey);
  @include theme-color-picker($color-black, null);
  border: 1px solid $color-lightest-grey !important;
}

.cancel-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-Helvetica-medium !important;
  // border-radius: 0.3rem !important;
  @include theme-color-picker(null, $color-white);
  @include theme-color-picker($color-black, null);
  border: 1px solid $color-black !important;
}

.primary-outline-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0rem !important;
  @include theme-color-picker($theme-color-primary, null);
  border: 1px solid $theme-color-primary !important;
}

.choose-default-style {
  border-radius: 10px !important;
  min-height: 2.5rem !important;
  min-width: 7.313rem !important;
  font-family: $font-semi-bold !important;
  @include theme-color-picker(null, $color-light-blue);
  @include theme-color-picker($theme-color-primary, null);
}

.reject-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0rem !important;
  @include theme-color-picker($color-ghost-white, null);
  @include theme-color-picker(null, $color-cinnabar);
}

.warning-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0rem !important;
  @include theme-color-picker($color-black, null);
  @include theme-color-picker(null, $color-lightning-yellow);
}

.success-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 0rem !important;
  @include theme-color-picker($color-ghost-white, null);
  @include theme-color-picker(null, $color-green-apple);
}

.new-submit-style {
  min-height: 2.875rem !important;
  min-width: 6.05rem !important;
  box-shadow: none !important;
  font-family: $font-semi-bold !important;
  border: 2.4px solid #f7b738 !important;
  border-radius: 6px !important;
  background-color: $color-white !important;
  color: #f7b738 !important;
}

// Application icons properties

.delete-icon-default-block {
  & .delete-icon-default-style {
    background-color: $color-pearl-red;
    min-width: 24px;
    min-height: 25px;
    border-radius: 3px;
    opacity: 1;
    display: none;
  }

  &:hover .delete-icon-default-style {
    display: inline-block;
    cursor: pointer;
  }
}

/* below will used for all documents download on hover*/

.attachment-hover-download {
  & .download-icon {
    display: none;
  }

  &:hover .download-icon {
    display: block !important;
  }
}

// Modal button properties

.modal-create-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  box-shadow: $box-shadow-none !important;
  @include theme-color-picker(null, $theme-color-primary);
  @include theme-color-picker($color-white, null);
}

.modal-submit-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: $modal-button-default-border-radius !important;
  box-shadow: $box-shadow-none !important;
  @include theme-color-picker(null, $color-green-apple);
  @include theme-color-picker($color-white, null);
}

.modal-cancel-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  // border-radius: $modal-button-default-border-radius !important;
  @include theme-color-picker($color-gray, null);
  border-bottom: 1px solid $color-gray;
}
.mat-mdc-outlined-button {
  border-radius: 0px !important;
}

.modal-delete-default-style {
  min-height: 2.775rem !important;
  min-width: 6rem !important;
  font-family: $font-semi-bold !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-color: $color-cinnabar !important;
  color: $color-white !important;
}

/* Application custom text/sentence epllipsis */

.custom-twoline-sentence {
  display: -webkit-inline-box;
  -webkit-box-orient: $webkit-box-orient !important;
  -webkit-line-clamp: 1 !important;
  overflow: $overflow-hidden !important;
}

.bulk-footer-action {
  position: fixed;
  bottom: 0;
  background: $color-white;
  z-index: 999;
  width: 100%;
  margin-left: -12px;
  margin-right: 0;
  margin-bottom: 0;
  box-shadow: 0px -3px 6px #00000014 !important;
  .action-btns {
    display: block !important;
    float: right !important;
  }
}

.custom-word-ellipse {
  white-space: $white-space-nowrap !important;
  overflow: $overflow-hidden !important;
  text-overflow: $text-overflow !important;
  max-width: 200px;
}

/* Scroll bar  */

::-webkit-scrollbar {
  width: 0.5em;
  height: 0.5rem;
}

::-webkit-scrollbar:hover {
  width: 0.5em !important;
  height: 0.5rem;
}

::-webkit-scrollbar-thumb {
  background-color: #dee2e6;
  border-radius: 1rem;
}

/* Application mat form field padding bottom remove when required */

.removeInputBottomPadding {
  padding-bottom: 0px !important;

  // Specific MDC classes might be necessary, depending on the structure
  .mdc-text-field {
    padding-bottom: 0px !important;
    display: flex !important;
    align-items: center !important;
  }

  .mat-mdc-text-field-wrapper {
    padding-bottom: 0px !important;
  }
}

/* Application mat form field apply space on label when prefix icon appears */

.applyInputLabelSpace {
  .mat-form-field-appearance-outline .mat-form-field-outline-start {
    width: 34px !important;
  }
}

.icon-size {
  width: 1rem;
}

custom-input {
  .mat-icon {
    color: $color-Bright-grey;
  }
}

/* modal popup */

.sub-body {
  overflow: auto;
  max-height: 400px;
}

.clearBtn {
  background-color: transparent !important;
  box-shadow: none !important;

  &:hover {
    background-color: $color-White-Lilac !important;
  }

  .customTableFilterLabelClass {
    color: #a5a5a5;
  }
}

.clearBtn.active {
  .customTableFilterLabelClass {
    color: $theme-color-primary;
  }
}

.min-width-auto {
  min-width: auto !important;
}

.text-danger {
  color: $color-cinnabar;
}

.custom-stepper {
  .mat-step-label {
    white-space: normal !important;
    height: auto;
    text-overflow: unset !important;
  }

  .mat-step-label.mat-step-label-active {
    font-weight: 500;
    font-size: 14px;
    color: $color-white !important;
  }
  .mat-step-label-active.mat-step-label-selected {
    color: #879dad !important;
  }

  .mat-step-header .mat-step-icon {
    background: $color-white;
    color: #aaaaaa;
    box-shadow: inset 0px 0px 0px 2px rgb(255 255 255);
    border: 1px solid #aaaaaa;
    width: 48px;
    height: 48px;
  }

  .mat-step-icon-content {
    font-size: 18px;
    font-weight: 500;
  }

  .mat-step-header .mat-step-icon-selected,
  .mat-step-header .mat-step-icon-state-done {
    background-color: $color-light-blue;
    color: $color-white;
    box-shadow: inset 0px 0px 0px 4px rgb(255 255 255);
    border: 1px solid #3a4aa0;

    .mat-step-icon-content {
      font-weight: 600;
    }
  }

  .mat-step-header .mat-step-icon-state-edit {
    background-color: #4bae4f;
    border: none !important;
    box-shadow: none !important;

    .mat-step-icon-content {
      color: #004f6b;
      font-weight: 600;
    }
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after,
  .mat-stepper-horizontal-line {
    border-top-color: #e8e8e8;
    border-top-color: #c1c1c1;
    border-top-width: 0.0999rem !important;
    top: 2.1rem !important;
  }

  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::before,
  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::before,
  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:first-child)::after,
  .mat-stepper-label-position-bottom
    .mat-horizontal-stepper-header:not(:last-child)::after {
    width: calc(50% - 24px) !important;
  }

  .mat-horizontal-content-container {
    padding: 0rem !important;
    overflow: visible !important;
  }

  .mat-stepper-horizontal {
    padding: 0px;
  }

  .mat-horizontal-stepper-header-container {
    padding: 0px 15rem;
    background: $color-dark-blue;
    border: 1px solid $color-dark-blue;
  }

  .mat-horizontal-stepper-header {
    box-sizing: border-box !important;
    flex-direction: column !important;
    height: auto !important;
    padding: 0.6rem 0px !important;
  }
}

.mdc-tab-indicator--active {
  color: $color-dark-blue !important;
  opacity: 1 !important;
  font-weight: 600;
}

.green .mat-progress-bar-fill::after {
  background-color: #55be58 !important;
}
.danger .mat-progress-bar-fill::after {
  background-color: #cb2727 !important;
}

.mat-form-field-disabled
  .mat-mdc-text-field-wrapper
  .mat-mdc-text-field-flex
  .mat-form-field-outline {
  cursor: not-allowed !important;
  background: $color-light-grey-shade !important;
  opacity: 0.7;
  border-radius: 0.5rem;
}
.mat-form-field-disabled
  .mat-form-field-outline-thick
  .mat-form-field-outline-start {
  color: $color-light-grey-shade !important;
}
.mat-form-field-disabled
  .mat-form-field-outline-thick
  .mat-form-field-outline-end {
  color: $color-light-grey-shade !important;
}

.mat-ink-bar {
  background: $color-dark-blue !important;
  height: 0.2rem !important;
}

.status_tag {
  padding: 0.4rem 0rem;
  font-size: 0.8rem;
  display: inline-block;
  border-radius: 4px;
  min-width: 6rem;
  text-align: center;
  font-weight: 600;
}
.document_status::first-letter{
   text-transform: capitalize;

}
.document_status {
  background-color: var(--background-color, #228B22) !important;
  color: var(--text-color, $color-white) !important;
  border-radius: 0px !important;
  font-weight: 500;
  font-family: $font-Helvetica-regular;
  padding: 1px 5px;
  @extend .status_tag;

  &[name*="Open"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-dark-purple);
  }

  &[name*="Draft"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-yellow-light);
  }

  &[name*="Submitted"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-light-orange);
  }

  &[name*="Rejected"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-cinnabar);
  }

  &[name*="Received"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-light-spring-green);
  }

  &[name*="Approved"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-dark-green);
  }
  &[name*="Acknowledged"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-light-cyan);
  }
  &[name*="Assigned"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-lightest-pink-fade);
  }
  &[name*="Differ"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-light-pink-fade);
  }
  &[name*="Completed"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-light-gold);
  }
  &[name*="--"] {
    @extend .status_tag;
    @include theme-color-picker($color-black, $color-dove-gray);
  }
}

.common_status_bg {
  @extend .status_tag;
  @include theme-color-picker($color-white, $color-gray);
  &[name*="valuation_received"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-received);
  }
  &[name*="valuation_acknowledge"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-acknowledged);
  }
  &[name*="valuation_assign"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-assigned);
  }
  &[name*="valuation_differ"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-defered);
  }
  &[name*="valuation_complete"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-completed);
  }
  &[name*="valuation_review"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-reviewed);
  }
  &[name*="valuation_approve"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-approved);
  }
  &[name*="valuation_dispatch"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-dispatched);
  }
  &[name*="valuation_resubmitted"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-darkest-blue);
  }
  &[name*="valuation_accept"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-accepted);
  }
  &[name*="valuation_appeal"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-appealed);
  }
  &[name*="valuation_submit"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-submitted);
  }
  &[name*="valuation_recommend"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-forwarded);
  }
  &[name*="valuation_revaluate"] {
    @extend .status_tag;
    @include theme-color-picker($color-white, $color-revaluated);
  }
}
.apexcharts-toolbar {
  .fa-download {
    border: 1px solid !important;
    padding: 0.3rem !important;
    color: $color-dark-blue !important;
    border-radius: 0.1rem;
  }
  .fa-plus {
    border: 1px solid !important;
    padding: 0.5rem !important;
    color: $color-dark-blue !important;
    border-radius: 0.1rem;
    margin: -0.12rem 0 0 -2rem;
  }
  .fa-minus {
    border: 1px solid !important;
    padding: 0.5rem !important;
    color: $color-dark-blue !important;
    border-radius: 0.1rem;
    margin: -0.12rem 0 0 -0.6rem;
  }
}

.apexcharts-menu-item.exportSVG {
  display: none !important;
}

.font-weight {
  font-weight: normal !important;
}

.apexcharts-xaxis-title {
  font-weight: 900 !important;
}
.apexcharts-yaxis-title {
  font-weight: 900 !important;
}

.remove-padding-accordion {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }
}

button.valuation_appeal,
button.valuation_revaluate {
  background-color: $color-red-shade !important;
  .btn-text {
    color: $color-white !important;
  }
}
button.valuation_accept {
  background-color: $color-green-primary !important;
  .btn-text {
    color: $color-white !important;
  }
}
button.valuation_dispatch,
button.valuation_review {
  background-color: $color-light-green !important;
  .btn-text {
    color: $color-white !important;
  }
}

.open_status {
  background-color: #2fa20a;
  color: $color-white;
}

.inprogress_status {
  background-color: #ebbc46;
  color: $color-black;
}

.close_status {
  background-color: #ef4949;
  color: $color-white;
}

.response_status {
  @extend .status_tag;
  @include theme-color-picker($color-Deep-Lavender, $color-Blue-chalk);
}

.active_status {
  color: green;
}

.mat-drawer-container-has-open {
  .valution-footer-action,
  .bulk-footer-action {
    width: calc(100% - 15.15rem) !important;
  }
}

.timepicker-overlay {
  z-index: 1100 !important;
}

.timepicker-backdrop-overlay {
  z-index: 1100 !important;
}

.mdc-dialog__container {
  padding: 0px !important;
  border-radius: 0rem 0rem 0.4rem 0.4rem !important;
  box-shadow: $dialog-default-box-shadow !important;

  &::-webkit-scrollbar-track {
    display: $display-none;
  }
}

.timepicker__header {
  background-color: $color-mediumslate-blue !important;
}

.clock-face__clock-hand {
  background-color: $color-mediumslate-blue !important;
}

.clock-face__number > span.active {
  background-color: $color-mediumslate-blue !important;
}

.timepicker-button {
  background: $color-silver !important;
  padding: 0rem;
  color: $color-black !important;
  margin-left: 1rem !important;
}
.toggle {
  position: absolute;
  right: 2.4rem;
  top: 0.5rem;
  display: flex;
  justify-content: end;
  z-index: 99;
  bottom: 0;
  margin: auto;
  height: max-content;
  button.ngx-material-timepicker-toggle {
    height: max-content;
  }
  path {
    stroke-width: 0rem;
  }
}

.mat-optgroup-label {
  background: rgba(149, 162, 234, 0.1490196078);
  color: #0a1652;
  font-weight: 700;
  font-size: 1rem;
}

.mat-date-range-input-container {
  justify-content: flex-start !important;
}

.ngx-material-timepicker-toggle {
  height: 5.7rem;
  max-height: 5.7rem !important;
}

.custom-table-cell-head {
  white-space: nowrap;
  border-bottom: none !important;
}

.custom-table-head-row {
  td {
    border-bottom: 1px solid #ebebeb;
  }
}

.ng-otp-input-wrapper {
  justify-content: space-between;
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.otp-input {
  border-radius: 0rem !important;
  font-size: 1.5rem;
}

.ol-zoom {
  top: 75% !important;
  right: 0.5em !important;
  left: auto !important;
}

.ol-zoom-in,
.ol-zoom-out {
  border: none !important;
  border-radius: 5px !important;
  width: 34px !important;
  height: 34px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  text-decoration: none !important;
  transition: background-color 0.3s;
  cursor: pointer;
}

.ol-control {
  position: absolute !important;
  background-color: transparent !important;
  border-radius: 1px;
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.ol-zoom .ol-unselectable .ol-control {
  position: absolute !important;
  bottom: 10px !important;
  right: 10px !important;
}

.placholder_tag {
  background: #e2e2e2c7;
  padding: 0.4rem 0.6rem;
  border-radius: 1.4rem;
  line-height: 2.2rem;
  white-space: nowrap;
  margin: 0.5rem 0.7rem 0.7rem 0.4rem;
}

.self-register-form .mat-mdc-checkbox {
  position: relative;
  top: -2.2rem;
  right: 6.8rem;
}

/* Ensure proper box-sizing */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Base styles */
.is-not-applicable {
  position: absolute;
  right: 0%;
  text-align: right;
  margin-top: -1rem;
  max-width: 100%; /* Ensure the container does not exceed the viewport width */
  overflow: hidden; /* Hide overflow content */
}

.edit-dynamic-form .is-not-applicable custom-checkbox mat-label {
  float: right !important;
}

.edit-dynamic-form .is-not-applicable .mat-mdc-checkbox {
  margin-top: -10px !important;
}

.ml-1 {
  margin-left: 10px;
}

.deleteBtn {
  color: red;
}
/* Adjustments for different screen sizes and scaling */

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 576px) {
  .is-not-applicable {
    margin-top: -1rem;
    padding-right: 5%; /* Add padding to keep content within the viewport */
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 768px) {
  .is-not-applicable {
    margin-top: -1.2rem;
    padding-right: 7%;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 992px) {
  .is-not-applicable {
    margin-top: -1.3rem;
    padding-right: 8%;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1200px) {
  .is-not-applicable {
    margin-top: -1.4rem;
    padding-right: 9%;
  }
}

.text-muted i {
  color: $theme-color-primary;
}

.disable-dynamic-form .custom-select .mat-mdc-form-field {
  background: #f6f8f9 0% 0% no-repeat padding-box !important;
  border: 1px solid #c7d3dc !important;
  pointer-events: none !important;
  cursor: default !important; /* Optional: change the cursor to indicate it's not clickable */
  opacity: 0.5;
}

.regn-success-panel {
  /* UI Properties */
  background: #eeeeee;
  padding: 5px;
  text-align: left;
}

.top-red-line {
  border-top: 5px solid $container-line-color;
}
.dict {
  height: 12px;
  width: 12px;
  background: $container-line-color;
}

.submit_flx {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fixed-bottom {
  position: sticky !important;
  bottom: 48px !important;
  z-index: 999;
  background: $color-white;
  box-shadow: 0 2px 6px #b5b4b4;
}

.footer-section {
  position: fixed;
  bottom: 0;
  z-index: 999;
  background: $color-white;
  box-shadow: 0 2px 6px #999;
}

.section-notes {
  background: #b7f5ff 0% 0% no-repeat padding-box;
  width: 100%;
  padding: 10px;
  padding-left: 20px;
}

.note-desc {
  padding-left: 30px !important;
  padding-top: 3px;
  color: $theme-onboard-title;
  font-size: 13px;
  font-family: $font-Helvetica-thin;
}

.toggle-icon {
  cursor: pointer;
}
