@import "../src/styles/global/variable";

.material {
  @import "../node_modules/@ng-select/ng-select/themes/material.theme";
}

.default {
  @import "../node_modules/@ng-select/ng-select/themes/default.theme";
}

.border-line {
  border: 1px solid $border-line-color;
}

@media print {
  .no-print {
    display: none;
  }

  body {
    font-size: 12pt;
    margin: 0;
    padding: 20px;
  }

  h1,
  h2,
  h3 {
    color: $color-black;
    page-break-after: avoid;
  }

  a {
    text-decoration: none;
    color: $color-black;
  }

  /* Adjust images */
  img {
    max-width: 100%; /* Scale images to fit the page */
    height: auto; /* Maintain aspect ratio */
  }

  /* Add page breaks */
  .page-break {
    page-break-after: always; /* Force a page break after this element */
  }
}

.custom-table-checkbox {
  .mdc-checkbox {
    padding: 0 !important;
  }

  /* Set font size and margins for printed content */
  body {
    font-size: 12pt; /* Adjust font size for readability */
    margin: 0; /* Remove default margin */
    padding: 20px; /* Add padding for printed content */
  }

  /* Style headers */
  h1,
  h2,
  h3 {
    color: $color-black; /* Ensure headers are printed in black */
    page-break-after: avoid; /* Prevent page breaks after headers */
  }

  /* Style links */
  a {
    text-decoration: none; /* Remove underline from links */
    color: $color-black; /* Print links in black */
  }

  /* Adjust images */
  img {
    max-width: 100%; /* Scale images to fit the page */
    height: auto; /* Maintain aspect ratio */
  }

  /* Add page breaks */
  .page-break {
    page-break-after: always; /* Force a page break after this element */
  }
}

.mdc-checkbox {
  padding: 0 !important;
}

.mat-mdc-checkbox-touch-target {
  width: 50px !important;
  height: 50px !important;
}
.mdc-checkbox .mdc-checkbox__native-control {
  width: 20px !important;
  height: 20px !important;
}
.mdc-checkbox__background {
  top: auto !important;
  left: auto !important;
}
.mat-mdc-checkbox .mat-internal-form-field {
  padding-left: 1rem !important;
}

.mdc-checkbox__background {
  border-radius: 0px !important;
  border: 1px solid $color-boulder-grey !important;
  border-color: $color-boulder-grey !important;
  top: 0px !important;
  left: -29px !important;
}
.agree-btn {
  padding: 10px 0px;
}
.agree-btn .mdc-checkbox__background {
  left: unset !important;
  top: -10px !important;
}
.agree-btn .mdc-label {
  white-space: nowrap;
  padding-left: 10px;
}
.msg-container {
  background-color: $confirmation-bg;
}
.txt_forecast {
  color: $view-label-clr;
  padding: 0px 20px;
  font-size: 14px;
  font-family: $font-Helvetica-thin;
}
.border_forecast {
  border-top: 0.5px solid $border-before;
  border-left: 0.5px solid $border-before;
}
.border_forecast_column {
  border: 0.2px solid $border-before;
  border-top: 0px;
  border-left: 0px;
  padding: 5px 15px;
}

span.site-list-container {
  padding: 3px 7px;
  background-color: $agree-bg;
  border-radius: 0px;
  margin: 4px;
  min-width: 4rem;
  text-align: left;
  display: inline-block;
}

.eye-icon-show-hide-pswrd {
  position: absolute;
  z-index: 99;
  top: 14px;
  bottom: auto;
  margin: auto;
  height: max-content;
  right: -30px;
  cursor: pointer;
}

.margin_icn .mat-mdc-form-field-infix {
  margin-right: 30px;
}

.col_data {
  margin-bottom: 5px !important;
  max-height: 500px;
  overflow-x: scroll;
}

.active-inactive {
  mat-label,
  span {
    border: 1px solid $border-line-color;
    text-align: center;
    font-size: 1rem !important;

    &.status-active {
      color: $color-black !important ;
      background-color: $color-light-greenish;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: $font-Helvetica-regular;
    }
    &.custom-bg-yes {
      color: $color-white !important ;
      background-color: $color-light-green;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
      font-family: $font-Helvetica-regular;
    }
    &.status-in-active {
      color: $color-black !important;
      background-color: $status-inactive-bg;
      font-family: $font-Helvetica-regular;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
    }
    &.status-inactive {
      color: $color-black !important;
      background-color: $color-white !important;
      font-family: $font-Helvetica-regular;
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }
}
.active-inactive mat-label {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mdc-line-ripple--active {
  border-bottom: 1px solid transparent !important;
}
.flex_div_date {
  flex: 0 0 25%;
}
.flex_div_2 {
  flex: 0 0 75%;
}
html {
  font-size: 14px;
}

body {
  font-family: $font-Helvetica-regular;
}

.mat-mdc-form-field-subscript-wrapper::before {
  display: none !important;
}
.mat-mdc-form-field-bottom-align::before {
  content: none !important;
  display: none !important;
}
.mdc-floating-label--required:not(
    .mdc-floating-label--hide-required-marker
  )::after {
  content: none !important;
}
.mat-vertical-stepper-header {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 20px 25px !important;
}
.mat-mdc-notch-piece {
  border-radius: 0 !important;
  border-top: 1px solid $color-black-tp !important;
}
.mdc-floating-label--float-above mat-label {
  font-size: 18px !important;
  color: $view-label-clr;
  position: relative;
  top: -5px;
  background-color: $color-white;
  padding: 0px 7px;
}

.mdc-floating-label mat-label {
  position: relative;
  top: -5px;
}
.mdc-dialog--open .mat-mdc-dialog-surface,
.mdc-dialog--closing .mat-mdc-dialog-surface {
  border-radius: 0px;
}
.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate
  ~ .mdc-checkbox__background {
  border-color: $color-primary-blue !important;
  background-color: $color-primary-blue !important;
}
.header_logo_flx {
  display: flex;
  align-items: center;

  gap: 10px;
}
.header {
  background: $color-white;
  padding: 5px 15px 5px 25px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.d_flx_rwanda {
  display: flex;
  gap: 5px;
  align-items: center;
}
.header_height {
  height: 63px;
}
mat-label {
  font-size: 14px;

  color: $view-label-clr;
  font-family: $font-Helvetica-regular;
}
.registration_txt {
  color: $primary-txt-clr;
  font-family: $font-Helvetica-thin;
}
.font_family_thin {
  font-family: $font-Helvetica-thin;
}
.address_company {
  color: $view-label-clr;
  font-family: $font-Helvetica-regular;
}

// mat tab design changes ........................

.mat-mdc-tab-list {
  padding: 0 1rem;

  .mat-mdc-tab-labels {
    width: max-content;
    border: 1px solid $color-primary-blue;
    background-color: $color-white;
  }

  .mat-mdc-tab {
    border-right: 1px solid $color-primary-blue;
  }
  .mat-mdc-tab:last-child {
    border-right: 1px solid transparent;
  }

  .mdc-tab-indicator--active {
    .mdc-tab__text-label {
      color: $color-white !important;
    }
  }
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 40px;
}

.mat-mdc-tab-body {
  padding: 0 1rem;

  .mat-mdc-card-content {
    padding: 0 0 !important;
  }
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-radius: 0px;
}

.mat-mdc-card-content:first-child {
  padding-top: 0px !important;
}

.custom-table-container
  .custom-table
  .sticky-table-last-two
  td:nth-last-child(2) {
  border-left: none !important;
}

.page_bg {
  background: $body-bg;
}
.mat-mdc-snack-bar-container .mat-mdc-snackbar-surface {
  background-color: transparent !important;
}
.mat-mdc-snack-bar-actions .mdc-button__label {
  color: $color-white !important;
}

// ........................mat tab design changes
// view registration mat design ...................

.agree-btn .mat-mdc-checkbox .mat-internal-form-field {
  padding-left: 0 !important;
}

.view-registration {
  .mdc-tab__text-label {
    letter-spacing: 0.7px;
    font-family: $font-Helvetica-regular;
  }
  .mat-mdc-tab-labels {
    width: max-content;
    border: 1px solid $color-white;
    background-color: $color-white;
  }
  .mat-mdc-tab {
    border-right: none;
  }
  .mdc-tab-indicator--active {
    .mdc-tab__text-label {
      color: $primary-txt-clr !important;
      font-weight: 700;
    }
  }
  .mat-mdc-tab-header {
    --mdc-secondary-navigation-tab-container-height: inherit;
    border-bottom: 1px solid $border-line-color;
  }
}
// ................... view registration mat design
/////list   status color

.closed_status {
  background-color: #7dda58;
  color: $color-white;
}

.pending_status {
  background-color: #ffde59;
  color: $color-black;
}

.open_status {
  background-color: #ef4949;
  color: $color-white;
}

.status_align {
  font-size: 13px;
  border-radius: 0rem;
  display: flex;
  justify-content: center;
  padding: 4px 0px 4px 0px;
  vertical-align: middle;
}

.status_align_sts {
  position: relative;
  top: 3px;
  vertical-align: middle;
  display: inline-block;
  padding: 2px 5px;
}
.mdc-switch__track::after {
  background: #89df77 !important;
}
.mdc-switch--selected:enabled .mdc-switch__handle::after {
  background: #89df77 !important;
}
.section-card .input_suffix_text {
  padding-bottom: 0px;
}

.custom-table input::-webkit-input-placeholder {
  color: $contact-border-clr;
  font-size: 13px;
}
.custom-table input:focus-visible {
  border: none;
  outline: none;
}

// user management tab style....................

.user-mgnt-tab-btn {
  padding: 10px 21px;
  text-align: center;
  border: 0.5px solid $color-primary-blue;
  border-right: 1px solid transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  &.active {
    color: $color-white;
    background-color: $color-primary-blue;
  }
  &:last-child {
    border-right: 0.5px solid $color-primary-blue;
  }
}
.filter_flx {
  align-items: center;
  padding-left: 23px;
  padding-right: 23px;
  padding-top: 6px;
  min-height: 75px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid $dropdown-border-clr;

  .list_heading {
    white-space: nowrap;
    font-size: 20px;
    font-weight: 700;
    color: $text-font-default;
    font-family: $font-Helvetica-regular;
  }
  .users_clr {
    color: $text-font-default;
    font-size: 13px;
    display: inline-block;
    margin: 5px 0px;

    font-family: $font-Helvetica-regular;
  }
}
.custom_filter_flx {
  display: flex;
  align-items: center;

  justify-content: end;
}
.custom_filter_flx1 {
  row-gap: 10px;
  flex-direction: column;
  display: flex;

  justify-content: end;
}
.users_grp {
  justify-content: unset;
  width: 100%;
}
.form_end .mat-date-range-input-inner::-webkit-input-placeholder {
  color: $color-black !important;

  font-family: $font-Helvetica-regular !important;
}
.form_end .mat-date-range-input-mirror {
  font-size: 13px !important;
  color: $color-black !important;

  font-family: $font-Helvetica-regular !important;
}
.mat-start-date::-webkit-input-placeholder {
  font-size: 13px !important;
}
.form_end .mat-date-range-input-inner {
  font-size: 12px !important;
  color: $color-black !important;

  font-family: $font-Helvetica-regular !important;
}

.users_end {
  display: flex;
  flex-wrap: wrap;

  gap: 2px;

  /* margin-right: 20px; */
  justify-content: end;
  width: 100%;
  align-items: center;
  margin: 10px 18px 10px 0px;
  padding-right: 10px;
  border-right: 1px solid $border-before;
}

.form_end {
  column-gap: 15px;
  row-gap: 5px;

  display: flex;
  align-items: center;
  justify-content: end;
}
.mdc-list-item__primary-text {
  font-size: 13px !important;
}
.mat-mdc-button .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
}
.copyDataFromCheckbox {
  position: relative;
  top: -28px;
}
.adduser_btn {
  display: inline-block;
  margin-left: 5px;
  white-space: nowrap;
  letter-spacing: 0.5px;
}
.line_before {
  position: relative;
}
.line_before::before {
  content: none;
  position: absolute;
  width: 42px;
  transform: rotate(90deg);
  height: 1px;
  left: -37px;
  top: 20px;
  background-color: $border-before;
}
.d_flx_edit {
  display: flex;
  justify-content: end;
}

.edit_icn {
  justify-content: center;
  align-items: center;
  display: flex;
  width: 40px;
  height: 41px;
  margin-left: 5px;

  border: none;
  background: $edit-bg;
  // cursor: not-allowed;
}
.users_grp {
  font-size: 13px;
  font-family: $font-Helvetica-regular;
}
.pt_users {
  padding-top: 3.5px !important;
}
.mat-mdc-form-field-has-icon-suffix .mat-mdc-text-field-wrapper {
  padding-left: 20px;
}
td .mat-mdc-text-field-wrapper {
  padding-left: 0px !important;
}
.mat-mdc-text-field-wrapper {
  padding: 0px 0px 0px 20px !important;
}
custom-text-area .mat-mdc-text-field-wrapper {
  padding: 0px 20px 0px 20px !important;
}
custom-input .mat-mdc-text-field-wrapper {
  padding: 0px 20px 0px 20px !important;
}
custom-input[ng-reflect-input-form-control-name="ownership_percentage"]
  .mat-mdc-text-field-wrapper {
  padding: 0px 0px 0px 20px !important;
}
.div_name_search .mat-mdc-text-field-wrapper {
  padding: 0px 0px 0px 15px !important;
}
.div_name .mat-mdc-text-field-wrapper {
  padding: 0px 15px !important;
}
custom-date-picker .mat-mdc-text-field-wrapper {
  padding-right: 5px !important;
}
custom-date-picker[ng-reflect-input-date-picker-form-control-name="date_range"]
  .mat-mdc-text-field-wrapper {
  padding: 0px 0px 0px 15px !important;
}
.column_input_gap {
  margin-bottom: 26px;
}
.title_width_registration {
  position: relative;
}
.users-view-edit-page .mat-mdc-tab-body {
  padding: 0 0;
}
.tin_txt {
  display: inline-block;
  padding-left: 7px;
  font-family: $font-Helvetica-regular;
}
.title_width_registration::before {
  content: "";
  position: absolute;
  height: 12px;
  width: 12px;
  top: 17px;
  left: 0px;
  background: #da291c;
}

.multiselect-dropdown {
  .dropdown-btn {
    border-radius: 0 !important;

    background-color: $bg-white;
  }
  .dropdown-list {
    max-width: 200% !important;
    box-shadow: none !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
    margin-top: 5px !important;
    li {
      padding: 10px !important;
    }
  }
}
.mat-mdc-autocomplete-panel {
  min-width: 258px;
  border: 1px solid $dropdown-border-clr;
}
.custom-table-filter-row {
  .multi_jira {
    .multiselect-dropdown {
      .dropdown-btn {
        width: unset !important;
        display: flex !important;
        height: 38px;
        align-items: center;
        justify-content: space-between;
        padding-right: 5px !important;
        padding-left: 15px !important;
        .dropdown-multiselect--active {
          white-space: nowrap;
          display: flex;
          align-items: center;
        }
      }
      .dropdown-list {
        min-width: 240px;
      }
    }
  }
}
.selected-item {
  display: flex;

  span {
    display: inline-block;
    max-width: 100px !important; /* Ch unit is character-width-based */
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
}
.form_end .mat-mdc-input-element::-webkit-input-placeholder {
  color: $color-black !important;

  font-family: $font-Helvetica-regular !important;
}
input {
  color: $color-black !important;
}
.footer p {
  color: #14161c !important;
  font-family: $font-Helvetica-regular !important;
}

.mat-date-range-input.ng-invalid .mat-date-range-input-separator {
  display: none;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled):not(
    .mdc-text-field--focused
  ):hover
  .mdc-line-ripple::before {
  border: none;
}

.start_end_date
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  border: none;
}

.overflow_tab {
  overflow-x: auto;
  white-space: nowrap;
}

.overflow_tab::-webkit-scrollbar {
  display: none;
}
.overflow_tab {
  scrollbar-width: none;
}

.overflow_tab {
  -ms-overflow-style: none;
}
.mat-date-range-input.ng-valid .mat-date-range-input-separator {
  display: block;
}

.mat-date-range-input-end-wrapper span {
  position: relative;
}
.mat-calendar-table {
  border: none;
}
.scp_mb .margin_div {
  margin-bottom: 0px !important;
}
.scp_form_flex.form_end {
  flex-wrap: nowrap !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div:before {
  border: 1.5px solid $dropdown-border-clr !important;
  width: 17px !important;
  height: 16px !important;
}
.multiselect-item-checkbox input[type="checkbox"]:checked + div:before {
  background: $checkbox-bg !important;
}
.multiselect-item-checkbox input[type="checkbox"]:checked + div:after {
  background-image: url("./assets/images/common/login_page_usp/tick.svg") !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div:after {
  content: "";
  top: 54% !important;
  left: 4px !important;
  width: 7px !important;
  height: 2px !important;
  margin-top: -4px;
  box-shadow: none !important;
}
.multiselect-item-checkbox input[type="checkbox"] + div {
  font-family: $font-Helvetica-regular;
  line-height: 15px;
}
div.mat-mdc-autocomplete-panel {
  margin-top: 5px;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.mat-datepicker-content {
  margin-top: 5px;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid $border-before !important;
}
.multiselect-dropdown .dropdown-btn .selected-item-container .selected-item {
  border: 1px solid $color-primary-blue !important;
  margin-right: 4px;
  background: $color-primary-blue !important;
  padding: 0 5px;
  color: $color-white;
  border-radius: 0px !important;
  float: left;
  max-width: 100px;
}
.multiselect-dropdown .dropdown-btn > span:last-child {
  display: flex;
  align-items: center;
}
.dropdown-btn,
.dropdown-btn:focus-visible,
.dropdown-btn:hover {
  border: 1px solid $dropdown-border-clr !important;
}
.mdc-notched-outline__notch {
  border-left: 0px !important;
  border-top: 1px solid $dropdown-border-clr;
  border-bottom: 1px solid $dropdown-border-clr !important;
}
.mdc-button-base:hover,
.mdc-button-base:focus-visible,
.mdc-button-base:active {
  background-color: transparent !important;
}
.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field
  .mdc-notched-outline__notch {
  border-left: 1px solid rgba(0, 0, 0, 0);
  border-width: 1px !important;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mat-mdc-notch-piece {
  border-color: $dropdown-border-clr !important;
  border-width: 0px;
}
.mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--invalid
  .mat-mdc-notch-piece {
  border-color: $dropdown-border-clr !important;
}
.mdc-notched-outline__trailing {
  border: 1px solid $dropdown-border-clr !important;
  border-left: 0px !important;
}
.mdc-notched-outline__leading {
  border: 1px solid $dropdown-border-clr !important;
  border-right: 0px !important;
}
.dropdown-list .filter-textbox input {
  padding: 0 0 0 6px !important;
}

.custom-table-body-row:last-child td:nth-last-child(2),
.custom-table-body-row:last-child td:last-child {
  border-bottom: 1px solid #dee2e60f !important;
}
[_nghost-ng-c3991406756] {
  height: 50vh !important;
}
.overflow_scroll_table table th {
  background-color: #e0e3e3 !important;
  color: $text-font-default;
  font-family: $font-Helvetica-regular;
  font-size: 12px;
}
table td {
  border: 0px;
  color: $text-font-default;
}
table td div {
  color: $text-font-default;
  font-size: 13px;
  font-family: $font-Helvetica-regular;
}
table td div.dynamic-td-div {
  overflow: hidden;
  width: 10rem;
  text-overflow: ellipsis;
}
.hidden_div .customClass {
  overflow: hidden;
  width: 12rem;
  text-overflow: ellipsis;
}
.mat-date-range-input-separator {
  position: relative;
  left: -4px;
}
.fixed_top {
  position: fixed;
  top: 0;
  z-index: 999 !important;
}

.menu-name a {
  white-space: nowrap;
}
.login_div_two {
  height: 490px;
  width: 100%;
}
.h_full {
  height: 100%;
}

/* Tree Listing */

.filter-bar {
  align-items: center;
  column-gap: 10px;
}

.node-content-wrapper,
.tree-children {
  position: relative;
}

// .node-content-wrapper::before,
// .tree-children::after {
//   content: "";
//   position: absolute;
// }

.node-content-wrapper::before {
  border-bottom: 1px solid lightgrey;
  border-left: 1px solid lightgrey;
  height: 28px;
  top: -17px;
  width: 20px;
  left: -28px;
}
.node-content-wrapper:hover,
.node-content-wrapper:focus-visible {
  box-shadow: none !important;
  background: transparent !important;
  outline: none !important;
  border: none !important;
}
.node-content-wrapper-active,
.node-content-wrapper-focused,
.node-content-wrapper:hover {
  box-shadow: none !important;
}

.tree-node-level-1
  > tree-node-wrapper
  > .node-wrapper
  > .node-content-wrapper::before {
  display: none;
}

.tree-node-leaf > .node-wrapper > .node-content-wrapper::before {
  width: 25px;
}
.scp-onboarding-screen {
  padding-bottom: 10px;
  .mat-stepper-label-position-bottom .mat-horizontal-stepper-header-container {
    position: absolute;
    top: -76px;
    right: 0;
    z-index: 990;
  }
  .mat-horizontal-stepper-header {
    margin-top: 7px;
    background: #0b2036 !important;
    padding-inline: 24px;
    padding-top: 17px !important;
  }
  .mat-step-icon {
    border-radius: 0;
    height: 12px;
    width: 12px;
    background-color: $step-bg-disabled !important;
    &.mat-step-icon-state-edit {
      background-color: $selected-step-bg !important;
    }
  }
  .mat-step-label {
    padding-top: 5px !important;

    .mat-step-text-label {
      color: $step-text-disabled;
      font-family: $font-Helvetica-thin;
      font-size: 12px !important;
    }
  }
  .mat-step-label-selected {
    .mat-step-text-label {
      color: $color-white;
      font-family: $font-Helvetica-regular;
      font-size: 12px !important;
    }
  }
  .mat-step-label-active {
    .mat-step-text-label {
      color: $color-white;
      font-family: $font-Helvetica-regular;
      font-size: 12px !important;
    }
  }

  .mat-horizontal-stepper-header::before,
  .mat-horizontal-stepper-header::after {
    border-top: 2px dashed $border-line-color !important;
    top: 23px !important;
    width: calc(50% - 12px) !important;
  }

  .mat-step-icon-content {
    span {
      display: none;
      background-color: $border-line-color;
    }
  }
  .mat-step-icon-selected {
    background-color: $step-bg-clr !important;
  }

  .mat-horizontal-content-container {
    padding: 0px !important;
  }

  .mat-horizontal-stepper-wrapper {
    border-top: 4px solid $primary-bg-table-color;
  }
}

.tree-children::after {
  border-left: 1px solid lightgrey;
  height: 100%;
  top: -15px;
  left: -15px;
}

tree-node:last-child > .tree-node > .tree-children::after {
  border-left: none;
}

.toggle-children {
  z-index: 1;
}
.foot_height_cmn {
  height: 100px;
}
.mat-mdc-dialog-surface {
  margin: 20px 0px;
}
.node-content {
  display: flex;
  align-items: flex-start;
  column-gap: 10px;
}

button[mat-icon-button] {
  $large-size-button: 80px;
  $large-size-icon: 48px;

  &.icon-button-large {
    width: $large-size-button;
    height: $large-size-button;
    line-height: $large-size-button;
    .mat-icon {
      font-size: $large-size-icon;

      line-height: $large-size-icon;
      position: absolute;
      top: 0;
      left: 0;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }

  $small-size-button: 24px;
  $small-size-icon: 18px;

  &.icon-button-small {
    width: $small-size-button;
    height: $small-size-button;
    line-height: $small-size-button;
    .mat-icon {
      font-size: $small-size-icon;

      line-height: $small-size-icon;
      position: absolute;
      top: 0;
      left: 0;
    }
    .mat-button-ripple {
      font-size: inherit;
      width: inherit;
      height: inherit;
      line-height: inherit;
    }
  }
}

.custom-modal .file-exist[data-img-len="4"],
.file-exist[data-img-len="5"] {
  height: 145px !important;
}

/* Tree Listing End */

@media (max-width: 1511px) {
  .flex_col {
    flex-direction: column;
    gap: 10px;
  }
  .btn_column_end {
    width: 100%;
    margin-bottom: 5px;
  }
  .foot_height_cmn {
    height: 150px;
  }
}
@media (max-width: 1341px) {
  .scp_content {
    flex-direction: column;
  }
}
@media (max-width: 1270px) {
  .flx_users_col {
    flex-direction: column;
  }
  .w_full_users {
    width: 100%;
  }
  .w_full_filter {
    width: 100%;
  }
  .flx_users_col .width_filter {
    width: 30%;
  }

  .date_width {
    width: 235px !important;
  }
}
@media (max-width: 1200px) {
  .width_filter {
    margin: 5px 0px;
  }
  .users_end {
    flex-wrap: unset;
    border-right: 0px solid $color-white;
  }
  .scp_content .users_end {
    flex-wrap: wrap;
    border-right: 1px solid $border-before;
  }
  .calender_content .users_end {
    flex-direction: column;
  }
  .form_end {
    width: 100%;
  }
  .flx_roles_col {
    flex-direction: column;
  }

  .fixed_top {
    position: unset;
  }

  .d_flx_edit {
    margin-top: 10px !important;
    margin-bottom: 15px;
  }
  .line_before::before {
    content: none;
  }
  .line_before {
    width: unset;
  }
  .roles_col {
    flex-direction: column;
    gap: 10px;
  }
  .width_inspection .users_end {
    flex-wrap: wrap;
    justify-content: center;
  }
  .width_inspection .width_filter {
    width: -webkit-fill-available;
  }
}
@media (max-width: 1163px) {
  .scp_content {
    .w_full_users {
      flex-direction: column;
    }
    .users_end {
      flex-wrap: unset;
      flex-direction: column;
      border-right: 0px solid $border-before;
    }
    .d_flx_edit {
      width: 100%;
    }
  }
}

@media (max-width: 1148px) {
  .foot_height_cmn {
    height: 150px;
  }
  .flx_users_col .w_full_users {
    flex-direction: column;
  }
  .users_end_add_btn {
    width: 100%;
    justify-content: end;
  }
  .flx_entity_col {
    flex-direction: column;
  }
}

@media (max-width: 953px) {
  // .roles_end_add_btn {
  //   width: 100%;
  //   justify-content: end;
  // }
  .h_full {
    height: unset;
  }
  .new_user,
  #h_full_reset {
    position: unset !important;
  }
  #h_full_reset {
    margin-right: 0px !important;
  }
  .login_div_two {
    height: unset;
  }
}
@media (max-width: 992px) {
  .scp_mb .margin_div {
    margin-left: 7px !important;
    margin-right: 7px !important;
  }
  .foot_height_cmn {
    height: 150px;
  }
  .flx_users_col .form_end {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .flx_users_col .width_filter {
    width: 48%;
  }
  .flx_users_col .date_width {
    max-width: unset !important;
    width: 48% !important;
  }
  .flx_users_col .users_end {
    flex-direction: column;
  }

  .flx_users_col .users_aply {
    display: block;
  }
  .d_flx_edit {
    justify-content: end;
  }
  .login_div_two {
    margin-top: 50px !important;
  }
  .filter_flx {
    padding-left: 15px;
    padding-right: 15px;
  }
  .users_end {
    margin: 0px;
    padding-right: 0px;
  }
}
@media (max-width: 952px) {
  .flx_roles_col .width_filter {
    width: 48%;
  }
  .flx_roles_col .w_full_users {
    flex-direction: column;
  }
}
@media (max-width: 901px) {
  .border_left_usp::before {
    top: -7px !important;
  }
  .width_inspection .flx_roles_col .width_filter {
    width: 100%;
  }
  .width_inspection .form_end {
    width: unset;
    justify-content: unset;
    flex-wrap: wrap;
  }
  .width_inspection .date_width {
    max-width: 98% !important;
    width: 99% !important;
  }
  .mat-mdc-dialog-surface {
    margin: 20px 0px;
  }
}

@media (max-width: 852px) {
  .flx_entity_col .width_filter {
    width: 48%;
  }
  .flx_entity_col .date_width {
    max-width: unset !important;
    width: 48% !important;
  }
  .entity_end_add_btn {
    width: 100%;
    justify-content: end;
  }
  .flx_entity_col .w_full_users {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .flx_roles_col .users_end {
    flex-direction: column;
  }
  .phone_icn {
    margin-left: 0px !important;
  }
  .users_end {
    gap: 0px;
  }
  .scp-onboarding-screen .mat-step-label .mat-step-text-label {
    color: $color-black !important;
  }
  .scp-onboarding-screen .mat-vertical-stepper-header {
    align-items: baseline;
  }
  .custom-modal .file-exist[data-img-len="4"],
  .file-exist[data-img-len="5"] {
    height: 275px !important;
  }
}
@media (max-width: 600px) {
  .flx_entity_col .form_end {
    flex-direction: column;
  }
  .flx_entity_col .users_end {
    flex-direction: column;
  }
  .flx_entity_col .width_filter {
    width: 100%;
  }
  .flx_entity_col .date_width {
    max-width: unset !important;
    width: 100% !important;
  }
}
@media (max-width: 568px) {
  .border_left_usp::before {
    top: 7px !important;
  }

  .mat-vertical-stepper-header {
    padding: 15px 5px !important;
  }

  .flx_roles_col .form_end {
    flex-wrap: wrap;
    justify-content: unset;
  }
  .flx_roles_col .width_filter {
    width: 100%;
  }
  .flx_roles_col .date_width {
    max-width: unset !important;
    width: 100% !important;
  }
  .div_name_search .custom-input {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .flx_users_col .width_filter {
    width: 100%;
  }
  .flx_users_col .date_width {
    max-width: unset;
    width: 100% !important;
  }
}

.navbar-toggler-icon {
  background-image: url("src/assets/images/common/icons_usp/menu_img.jpg") !important;
}
.navbar-toggler,
.navbar-toggler:focus-visible,
.navbar-toggler:focus {
  border: 0px !important;
  outline: none !important;
  box-shadow: none !important;
}
// .................... user management tab style

// scp-onboarding-screen.....................

//view registration
.users-view-edit-page {
  .business-section {
    .view-section {
      padding-left: 1.5rem !important;
    }
    .section-card {
      padding-bottom: 1rem !important;
    }
  }
}
.section-card {
  padding-right: 0px !important;
}

.viewuser-section {
  .section-card {
    padding: 1rem !important;
    padding-top: 0px !important;
  }
}
.mat-mdc-checkbox .mat-internal-form-field {
  padding-left: 3rem !important;
}

// .....................scp-onboarding-screen
.mat-mdc-form-field-error {
  font-family: $font-Helvetica-regular;
  font-size: 12px;
}
.mat-mdc-form-field-error::first-letter {
  text-transform: uppercase;
}
.fs_icn {
  font-size: 14px;
  position: relative;
  top: -1px;
}
.collapse_heading {
  font-weight: 600;
  color: #292929;
  font-family: $font-Helvetica-regular;
  font-size: 14px;
}
.btn_default_user_delete {
  background-color: #d63322 !important;
  color: $color-white !important;
  outline: 0;
  border: 1px solid #979797;
  font-family: $font-Helvetica-medium;
  line-height: 0 !important;

  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.color_label {
  color: $view-label-clr;
  font-size: 12px;
  font-family: $font-Helvetica-thin;
}

.btn_default_user {
  background-color: $color-white !important;
  color: #747474 !important;
  outline: 0;
  border: 1px solid #979797;
  font-family: $font-Helvetica-regular !important;

  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.draft_save {
  background-color: $color-white !important;
  outline: 0;
  font-family: $font-Helvetica-regular !important;
  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
  border: 1px solid $color-black !important;
  color: $color-black !important;
  font-weight: 500;
}
.btn_login_user {
  background-color: $color-primary-blue !important;
  color: $color-white !important;
  outline: 0;
  font-family: $font-Helvetica-regular !important;
  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.header_height {
  height: 63px;
}

.btn_comments_user {
  background-color: $color-primary-blue;
  color: $color-white;
  outline: 0;
  border: 1px solid $color-primary-blue;
  font-family: $font-Helvetica-medium;
  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

.audit_log .mat-date-range-input {
  position: relative;
  top: -8px;
}
.active_sts {
  background-color: $color-light-greenish;

  display: table;
  padding: 2px 15px;
}
.inactive_sts {
  background-color: $color-sandal;

  display: table;
  padding: 2px 15px;
}

.audit_log .mat-unthemed img {
  top: -11px;
  position: relative;
}
.btn_login_user_small {
  background-color: $color-primary-blue !important;
  color: $color-white !important;
  outline: 0;
  border: 1px solid $color-primary-blue;

  font-family: $font-Helvetica-medium;
  padding: 12px 20px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.btn_default_user_small {
  background-color: $color-white !important;
  color: #747474;
  outline: 0;
  border: 1px solid #979797;
  font-family: $font-Helvetica-medium;

  padding: 12px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}
.btn_login_user.user_btn_border {
  border: 1px solid $color-primary-blue;
  background-color: transparent !important;
  color: $primary-txt-clr !important;
  font-weight: 500;
  opacity: 1 !important;
}
@media (max-width: 800px) {
  .scp-onboarding-screen {
    .mat-step-label {
      color: $color-black !important;
      font-size: 16px !important;
    }
    .mat-step-icon {
      margin-left: 8px;
    }
  }
  .mat-step-header:hover:not([aria-disabled]),
  .mat-step-header:hover[aria-disabled="false"] {
    background-color: transparent !important;
  }
  .mat-vertical-content {
    padding: 0 0px 0px 0px !important;
  }

  .custom-style-onboarding {
    .dict {
      display: none !important;
    }
  }
}
.custom-style-onboarding .padding_section {
  margin-top: -10px;
}
.status-history {
  color: $text-blue-clr;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-family: $font-Helvetica-regular;
}
.btn_clse {
  font-size: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.site-ellipsis {
  top: 1.5px;
  height: 22.5px;
  padding: 5px;
  background-color: #dee3ef;
  border-radius: 0px;
  margin-left: 2px;
  i {
    transform: rotate(90deg);
  }
  .site-container {
    display: none;
    position: absolute;
    z-index: 999;
  }
  &:hover {
    .site-container {
      display: block;
    }
  }
}
.translate-ellipsis {
  transform: rotate(90deg);
  padding: 6px 3px !important;
  height: unset !important;
  left: 6px;
}
.popover-arrow {
  display: none !important;
}
.popover {
  border-radius: 0px !important;
  margin-left: 10px !important;
}
.popover-body {
  padding: 5px !important;
}
.major-modal-actions {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid $dropdown-border-clr;
}

.major-modal-dummy {
  justify-content: flex-end;
}

.form-label {
  white-space: nowrap;
}
@media (max-width: 568px) {
  .contact-info {
    margin-top: 4px !important;
    flex-direction: column;
    align-items: unset !important;
    gap: 5px !important;
  }
  .border_mail {
    display: none;
  }
  .copyDataFromCheckbox {
    top: -26px !important;
  }
  .custom-style-onboarding .padding_section {
    margin-top: 25px !important;
  }
  .navbar {
    padding-left: 0px;
    padding-right: 0px;
  }
  .major-modal-actions {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .login_left_usp {
    padding: 30px 20px;
  }

  .mat-vertical-content-container {
    margin-left: 15px !important;
  }
  .new_user {
    align-items: unset;
  }
}

.category-body,
.level-body,
.location-body {
  padding: 0px 15px;
}
.custom-table-filter-row .mat-mdc-text-field-wrapper {
  height: 38px !important;
}

.custom_phonenumber {
  border: 1px solid #00000024 !important;

  height: 49px !important;
}
.file_info {
  margin-top: 5px;
  line-height: 10px;
  gap: 4px;
  align-items: flex-start;

  font-family: $font-Helvetica-regular !important;
}
.otp-input:focus-visible {
  outline: none !important;
}

.mat-date-range-input-wrapper {
  position: relative;
  overflow: hidden;
  width: fit-content;
  max-width: 60% !important;
}
.mat-date-range-input-hide-placeholders .mat-date-range-input-wrapper {
  position: relative;
  overflow: hidden;
  width: fit-content;
  max-width: 45% !important;
}

.mat-mdc-form-field-focus-overlay {
  background-color: $color-white !important;
}

.otp-input {
  font-size: 20px !important;
}
.section-border {
  padding-left: 15px;
  padding-right: 15px;
}

.width-top .cdk-textarea-autosize {
  margin-top: 0px !important;
}
.mdc-text-field--outlined {
  height: 48px !important;
}
.custom-text-area .mdc-text-field--outlined {
  height: auto !important;
}
.div_name .mat-mdc-form-field-infix {
  width: unset;
}
.login-screen {
  padding: 30px 0px;
}

.border_grievence {
  color: $color-black;
  font-weight: 550;
}
.title_template {
  color: $view-label-clr;
  display: inline-block;
  padding-top: 5px;
}
.border_scp {
  border: 1px solid $table-border;
}
.approve_border {
  padding: 5px 15px;
}
.approve_border:nth-child(odd) {
  border-right: 1px solid $table-border;
}
.is-not-applicable mat-label {
  color: #292929 !important;
  position: relative;
  left: 4px;
}
.view-status {
  border-radius: 0px;
  padding: 0px 15px;
  font-size: 13px;
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-line-ripple::after {
  border: none;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled)
  .mdc-line-ripple::before {
  display: none !important;
}
.forcast-inputField {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $color-white !important;
    height: 48px;
  }
}

.forcast-inputField.generate-uid {
  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: $color-white !important;
    height: 24px;
    box-shadow: none !important;
    border: none !important;
  }
  .mdc-text-field--focused {
    box-shadow: none !important;
    border: none !important;
    outline: none !important;
  }

  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    display: none !important;
  }
  input {
    padding: 0px 7px;
    font-size: 1rem;
    border: none !important;
    outline: none !important;
  }
}

.mat-mdc-dialog-surface {
  border-radius: 2px;
  overflow-y: auto;
  height: unset !important;
}

.mdc-button:disabled {
  background-color: #c7d3dc !important;
  opacity: 1;
}
.btn_login.mat-mdc-raised-button:not(:disabled) {
  background-color: $primary-txt-clr;
}
input[type="password"]::-ms-reveal {
  display: none;
}
input[type="password"] {
  font-family: "Courier New", Courier, monospace; /* Change to monospace for alignment */
  letter-spacing: 0.2em; /* Adjust spacing to look like asterisks */
  color: transparent;
  text-shadow: 0 0 0 #000; /* Masks each character with a shadow */
}
.login_left_usp {
  background-color: $login-left-bg;
}

.border_left_usp {
  position: relative;
}
.border_left_usp::before {
  content: "";
  width: 14px;
  position: absolute;
  height: 85px;
  top: 35px;

  left: -30px;
  background-color: $border-ryt-login;
}
.application-logo {
  position: relative;
  border-right: 1px solid $dropdown-border-clr;
  padding-right: 5px;
}
.rwanda_img {
  text-transform: uppercase;
  font-size: 13px;
}
.application-logo::before {
  content: "";
  position: absolute;
}
.forgot_div {
  margin: 20px 0px 30px 0px;
}
mat-form-field.ng-touched .mdc-text-field--invalid {
  border-radius: 0px !important;
  border: 1px solid #d92b1f !important;
}

.mdc-floating-label {
  background-color: transparent;
}
.mdc-text-field--invalid .mdc-floating-label {
  background-color: $color-white !important;
}
.disabled-field .mat-mdc-form-field {
  background: $color-white 0% 0% no-repeat padding-box !important;
}
.mdc-checkbox--selected:focus {
  box-shadow: none !important;
  outline: none !important;
  border: none !important;
}
.disable-file-upload .btn_choose {
  background-color: #c7d3dc !important;
}

.mdc-checkbox__checkmark {
  width: 81% !important;
  left: 2px !important;
  top: 1px !important;
}
.mdc-checkbox
  .mdc-checkbox__native-control:focus:checked
  ~ .mdc-checkbox__ripple {
  opacity: 0 !important ;
  background-color: unset !important;
}
.disable-dynamic-form .mat-mdc-text-field-wrapper {
  background-color: #f6f8f9;
}
.mdc-text-field--disabled mat-label,
.disable-file-upload .label_placeholder,
.disable-dynamic-form mat-label,
.disable-dynamic-form input::-webkit-input-placeholder {
  color: #a5a6a6 !important;
}
.margin_scp {
  font-size: 14px !important;
  position: relative;

  left: -2px !important;
  top: 0px;
  color: $user-text-clr;
}

custom-checkbox[ng-reflect-input-check-box-form-control-name="copy_shipping_address"]
  .margin_scp,
custom-checkbox[ng-reflect-input-check-box-form-control-name="copy_billing_address"]
  .margin_scp {
  left: -10px !important;
}

.is-not-applicable .margin_scp {
  color: $color-black !important;
  font-family: $font-Helvetica-thin;
}
.mdc-checkbox {
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
}
.mat-mdc-checkbox-touch-target {
  left: -23px !important;
}
.custom_suffix_txt {
  background: none !important;
  border-left: 0px solid #e0e0e0 !important;
  color: #84909a;
  font-size: 14px;
}
.secure_suffix {
  .custom_suffix_txt {
    background-color: $color-white !important;
    padding: 10px !important;
  }
  .mat-mdc-input-element {
    color: #1e5af9 !important;
  }
}
.sample_code {
  .custom-input .mat-mdc-text-field-wrapper {
    background-color: #f6f8f9 !important;
  }
}
.custom_phonenumber .mdc-line-ripple::before {
  border: 0px solid $color-white !important;
}

.custom_phonenumber .mdc-notched-outline__leading {
  border: 0px solid $color-white !important;
  border-bottom: 1px solid $color-black-tp !important;
}

.custom_phonenumber .mdc-notched-outline__notch {
  border: 0px solid $color-white !important;
  border-bottom: 1px solid $color-black-tp !important;
}
.custom_phonenumber .mdc-notched-outline__trailing {
  border: 0px solid $color-white !important;
  border-bottom: 0.5px solid $color-black-tp !important;
}

.disable-dynamic-form .custom_phonenumber .mat-mdc-form-field-infix {
  margin-bottom: -10px;
}
.disable-dynamic-form .custom_phonenumber .country_code {
  position: relative;
  top: -5px !important;
  width: 50px;
  margin: 0px 10px;
  color: #a5a6a6 !important;
  font-weight: 500;
}
.disable-dynamic-form
  .custom_phonenumber
  .country_code::-webkit-input-placeholder {
  color: #a5a6a6 !important;
  font-weight: 500;
  font-size: 15px;
}
:not(.disable-dynamic-form)
  .custom_phonenumber
  input.mat-mdc-autocomplete-trigger {
  position: relative;
  top: 0px;
  z-index: 999;
  color: $contact-border-clr !important;
  padding: 5px;
  background-color: #f9f8f6;
  font-size: 14px !important;
}
:not(.disable-dynamic-form)
  .custom_phonenumber
  .mat-mdc-form-field-focus-overlay
  .mat-mdc-input-element {
  background-color: $color-white !important;
}

.disable-dynamic-form .custom_phonenumber .mdc-notched-outline {
  background-color: unset;
}
.disable-dynamic-form .custom_phonenumber {
  background-color: #f6f8f9;
}
.disable-dynamic-form .mdc-text-field--filled.mdc-text-field--disabled {
  background-color: transparent;
}
.custom_phonenumber .mdc-notched-outline {
  background-color: $color-white;
}
.custom_phonenumber .country_code {
  position: relative;
  top: -5px;
  width: 50px;
  margin: 0px 10px;
}
.with-top-border:not(:first-of-type, :nth-child(2)) {
  border-top: 0px;
}
.with-top-border:nth-child(even) {
  border-left: 0 !important;
}
.with-top-border:not(:nth-child(1)):not(:nth-child(2)) {
  border-top: 0px;
}
.label_placeholder {
  font-size: 14px;
}
.mat-mdc-input-element {
  font-size: 16px;
}
.custom_phonenumber .mat-mdc-input-element::placeholder {
  font-size: 14px;
}
custom-input .mat-mdc-input-element {
  font-size: 16px;
}
.div_name_search .mat-mdc-input-element {
  font-size: 13px !important;
}
.div_name .mat-mdc-input-element {
  font-size: 13px !important;
  font-family: $font-Helvetica-bold;
}
.disable-dynamic-form .mat-mdc-input-element {
  font-size: 14px !important;
}
.cdk-text-field-autofill-monitored .ng-touched {
  font-size: 15px !important;
}

.table th:first-child,
.table tbody tr td:first-child {
  padding-left: 30px !important;
}

.disable-dynamic-form img[matdatepickertoggleicon] {
  opacity: 0.3;
}
.disable-dynamic-form .add_icn_div,
.disable-btn .add_icn_div {
  opacity: 0.3;
}
.add_icn_div {
  position: relative;
  top: -1px;
  opacity: 1;
}
img[matdatepickertoggleicon] {
  width: 15px !important;
  opacity: 1;
  cursor: pointer;
}
.text_file {
  font-family: $font-Helvetica-regular;
  font-size: 14px;
}
.border_mail {
  width: 2px;
  height: 16px;
  opacity: 0.5;
  border-right: 1px solid $color-dove-gray;
}
.contact_flx {
  display: flex;
  align-items: center;
}
.contact_flx_popup {
  display: flex;
  align-items: center;
  gap: 5px;
}
.preview_label {
  color: $color-white;
  display: inline-block;
  top: 2px;
  font-family: $font-Helvetica-regular;
  position: relative;
  span {
    background-color: $color-black;
    font-size: 11px;
    padding: 0px 5px;
  }
}
.preview_img {
  flex-direction: column;
  display: flex;
  img {
    max-height: 65px;
  }
}
.mail_ellipsis {
  width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
}
.copyDataFromCheckbox .d-flex {
  display: inline !important;
  position: relative;
  top: -10px;
}
.site-item {
  padding: 1rem;
  margin: 20px;
  background-color: #f6f8fa;
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid $edit-bg;

  &.selected {
    background-color: $primary-txt-clr;
    color: $color-white;
    font-family: $font-Helvetica-regular;
  }

  .site-info {
    flex-grow: 1;

    h4 {
      font-size: 13px;
      font-family: $font-Helvetica-regular;
      color: $color-black;
      margin: 0;
    }

    p {
      font-size: 13px;
      margin-bottom: 5px;
      font-family: $font-Helvetica-thin;
    }

    .contact-info {
      font-size: 13px;
      display: flex;
      gap: 1rem;
      font-family: $font-Helvetica-thin;

      color: $color-black;
      border-top: 1px solid $border-line-color;
      padding-top: 4px;

      mat-icon {
        font-size: 1rem;
        vertical-align: middle;
      }
      &.selected {
        color: $color-white;
      }
    }
  }
}
.site-item.selected h4 {
  color: $color-white;
}
.order-header {
  font-family: $font-Helvetica-regular;
  border: 1px solid #f7baba !important;
  background-color: #fff8f8 !important;
  margin: 2px 0px 15px 0px !important;
  padding: 12px 25px 5px;
}

.order-confirmation-container {
  padding: 16px;
  background-color: $color-white;
  font-family: Arial, sans-serif;
  border-radius: 8px;
}

.order-header h2 {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.order-header p {
  font-size: 12px;
  color: $view-label-clr;
  font-family: $font-Helvetica-thin;
}

.order-details {
  background-color: #f8f9fa;

  border: 1px solid #dee3ef;
}

.amount-row {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: $font-Helvetica-regular;

  padding: 13px 20px;
  border-bottom: 1px solid #dee3ef;
}

.total-amount-row {
  font-weight: bold;

  justify-content: space-between;
  color: #ff5733;
  font-family: $font-Helvetica-regular;
  padding: 13px 20px;
}

.label {
  font-size: 13px;
  color: $color-black;
}

.value {
  font-size: 13px;
  color: $color-black;
  font-family: $font-Helvetica-medium;
}

.total-value {
  font-size: 18px;
  cursor: pointer;
}
.color_txt {
  font-family: $font-Helvetica-medium;
  color: $records-txt-clr;
}
.footer-details {
  display: flex;
  border: 1px solid #dee3ef;
  margin-top: 10px;
}

.detail {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.detail-value {
  font-size: 11px;
  color: #343a40;
  font-family: $font-Helvetica-medium;
  margin-top: 0px;
}
.date_details {
  font-size: 12px;
}
.field-content1 {
  border-right: 1px solid #dee3ef;
  padding: 10px 66px 10px 15px;
  color: $view-label-clr;
}
.field-content2 {
  padding: 10px 0px 0px 15px;
  color: $view-label-clr;
}
.amt-label {
  color: #ff5733;
  font-size: 14px;
}
.info-icon {
  img {
    width: 14px;
    height: 14px;
    margin-left: 7px;
  }
}

.file_info {
  font-family: $font-Helvetica-regular !important;
}
.custom_phonenumber .flex_div_date {
  max-width: 65px;
}
.status-label {
  position: relative;
  top: -10px;
  color: $font-color-primary;
  font-family: $font-Helvetica-regular;
}

.border_view_col {
  flex-direction: column;
  display: flex;
  justify-content: center;
  align-items: baseline;
  border: 1px solid $table-border; /* Bottom border for each column */
  padding: 5px;
}

.border_view_col:nth-of-type(n + 4):nth-of-type(-n + 6),
.border_view_col:nth-of-type(n + 10):nth-of-type(-n + 12),
.border_view_col:nth-of-type(n + 16):nth-of-type(-n + 18) {
  border-top: 0px;
  border-bottom: 0;
}

.border_view_col:not(:nth-of-type(3)):not(:nth-of-type(6)):not(
    :nth-of-type(9)
  ):not(:nth-of-type(12)) {
  border-right: 0px;
}
.border_view_col:nth-of-type(1):only-of-type,
.border_view_col:nth-of-type(2):only-of-type {
  border-right: 1px solid $table-border;
}
/* Apply a bottom border to the last three .border_view_col elements */
.border_view_col:nth-last-of-type(-n + 3) {
  border-bottom: 1px solid $table-border !important; /* Adjust the border style as needed */
}

.border_view_col:last-child {
  border-right: 1px solid $table-border !important;
  border-bottom: 1px solid $table-border !important;
}
.margin_basic {
  margin-bottom: 20px;
}
.bulk_popup .bulk_dynamic {
  width: 50%;
}
.bulk_popup form {
  width: 50%;
}
.bulk_popup .column_input_gap {
  width: 100% !important;
}

@media (max-width: 1201px) {
  .border_view_col:nth-of-type(even) {
    border-right: 1px solid $table-border !important;
    border-bottom: 1px solid $table-border !important;
  }
}
@media (max-width: 850px) {
  .bulk_popup {
    flex-direction: column;
  }
  .bulk_popup form {
    width: 100%;
  }
  .download_details {
    padding: 0px 25px 20px;
  }
  .validate_doc {
    text-align: center;
  }
}
@media (max-width: 767px) {
  .border_view_col {
    border: 1px solid $table-border !important;
  }
  .border_view_col:nth-child(even) {
    border-top: 0px !important;
    border-bottom: 0px !important;
  }
  .nodata_details {
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
}
.start_end_date .mat-mdc-form-field-infix {
  width: 170px !important;
}
.start_end_date .mat-unthemed {
  width: 20px;
  padding: 0px;
  top: -2px;
  position: relative;
}
.start_end_date svg {
  width: 17px !important;
  top: -1px;
  position: relative;
  left: -4px;
}
caption {
  display: none;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  background-color: unset !important;
  opacity: 0 !important;
}
.mat-mdc-button-persistent-ripple,
.mat-mdc-button-touch-target,
.mat-ripple.mat-mdc-button-ripple {
  display: none !important;
}
.start_end_date .mat-ligature-font {
  font-size: 20px;
  position: relative;
  top: 1px;
}
th span {
  font-weight: 500;
  font-size: 13px;
}
.start_end_date .ng-touched .mdc-text-field--invalid {
  border: 0px solid transparent;
}
.user_name {
  color: $atch-txt-clr;
}
.text_align_left {
  text-align: left;
}
.para_txt_register {
  margin: 0px 8px 20px;
  line-height: 21px;
}
.bg_user_add {
  background-color: $modal-user-bg;
  padding: 10px;
  margin: 10px 0px 0px;

  span {
    font-size: 12px;
    font-family: $font-Helvetica-thin;
    color: $text-font-default;
  }
}
.bg_user_add_new {
  background-color: $modal-user-bg;
  padding: 10px;
  margin: 10px 12px 0px;
  span {
    font-size: 12px;
    font-family: $font-Helvetica-thin;
    color: $text-font-default;
  }
}
.user_name_display {
  margin-bottom: 0px;
  font-size: 14px;
  font-family: $font-Helvetica-regular;
  color: $text-font-default;
}
.label_txt_view {
  margin-bottom: 0 !important;
  font-size: 14px;
  font-family: $font-Helvetica-regular;
  color: $text-font-default;
  overflow: hidden;
  text-overflow: ellipsis;
}
.d_none_heading {
  display: none;
}

.delete_icon {
  width: 10px !important;
}

.roles_user_btn {
  .mdc-button:disabled {
    background-color: transparent !important;
  }
}
.apply_btn .mdc-button__label,
.clear_btn .mdc-button__label {
  font-size: 14px;
  letter-spacing: 0;
  font-family: $font-Helvetica !important;
}

.guest-footer {
  position: fixed;
  left: 0px;
  bottom: 30px;
  height: unset;
  width: 100%;
  /* UI Properties */
  background: $color-white;
  box-shadow: 0px -2px 10px #0000001a;
  opacity: 1;
  overflow: hidden;
  z-index: 9999;
}
.d_flex {
  display: flex;
}
.margin_scp {
  cursor: pointer;
}

tree-viewport {
  overflow: unset !important;
}

tree-node-children .tree-children {
  padding-left: 0px !important;
}
tree-node-children tree-node-collection .tree-column:first-child {
  position: relative;
  left: 5px;
}
tree-node-collection .toggle-children-wrapper-collapsed {
  position: relative;
  left: 5px;
}
tree-node-children .tree-children .toggle-children-wrapper-collapsed,
tree-node-children .tree-children .toggle-children-wrapper-expanded {
  position: relative;
  left: 15px;
  padding-right: 10px;
}

.angular-tree-component {
  width: 100% !important;
}
.node-content-wrapper-focused {
  background: transparent !important;
}
.toggle-children-wrapper {
  padding: 2px 9px 5px 12px;
}
@media (max-width: 1300px) {
  .angular-tree-component {
    width: unset !important;
  }
}
custom-table[tableid="cust-tbl-categoryList"] .mdc-checkbox__background {
  top: -10px !important;
}
.custom-Pagination-block {
  margin-bottom: 15px;
}
.view_header_flx {
  display: flex;
  padding: 10px 20px 5px;
}
.view_title_font {
  font-size: 20px;
  font-family: $font-Helvetica-regular;
  font-weight: 600;
  line-height: 25px;
}
.primary_txt_clr {
  color: $primary-txt-clr;
}
.left_arw {
  top: 3px;
  position: relative;
  font-size: 20px;
}

.checked-field {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.break_word {
  word-break: break-all;
}
.overflow_scroll_forcast .mdc-text-field--focused {
  border: 0px;
}

.title-card {
  text-align: left;
  font-size: 16px/21px;
  letter-spacing: 0.1px;
  color: #13316e;
  opacity: 1;
  margin-bottom: 5px;
}

.mat-mdc-menu-panel {
  border: 1px 1px 0px 1px solid $color-black-tp;
  box-shadow: 0px 3px 8px #8b8b8b29 !important;
  border-radius: 0px !important;
}
.mat-mdc-menu-content {
  padding: 0px !important;
}
@media (max-width: 700px) {
  .view_title_font {
    font-size: 16px;
  }
}
.item1 {
  .multiselect-item-checkbox {
    padding: 15px 10px !important;
    div {
      padding-top: 3px !important;
      padding-bottom: 3px !important;
    }
  }
}

.multi_jira .dropdown-list .item2 {
  min-height: 130px;
  max-height: 150px !important;
  margin: 5px 0px !important;
}
.dropdown-list li:hover {
  background-color: transparent !important;
}
.no-filtered-data {
  margin-top: 30px;
  h5 {
    text-align: center;
  }
}

.mat-step-header:hover {
  background-color: transparent !important;
}
.mat-step-header:active {
  background-color: transparent !important;
}
.mat-step-text-label {
  color: #13316e;
}
.mat-vertical-stepper-header .mat-step-icon {
  margin-top: -29px !important;
}
.cdk-keyboard-focused {
  background-color: $color-white !important;
}
.cdk-program-focused {
  background-color: transparent !important;
}
.mat-step-icon {
  border-radius: 50%;
  height: 24px;
  width: 24px;
  flex-shrink: 0;
  position: relative;
  color: var(--mat-stepper-header-icon-foreground-color);
  background-color: transparent !important;
}
.mat-vertical-stepper-header {
  height: 50px !important;
}
.mat-step-header {
  overflow: unset !important;
  padding-bottom: 0px !important;
}

.dialog-notification-wrapper {
  width: 400px;
  max-width: 400px !important;

  .notification-popup {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 12px;
      border-bottom: 1px solid #eee;

      h3 {
        margin: 0;
        font-size: 16px;
        color: #21396d;
        font-weight: 600;
      }
    }

    .search {
      .input-wrapper {
        position: relative;
        display: flex;
        align-items: center;

        input {
          width: 100%;
          padding: 8px 12px 8px 29px;
          border-top: 1px solid $edit-bg;
          border-bottom: 1px solid $edit-bg;
          border-left: 0;
          border-right: 0;
          color: $notification-input-txt-clr;

          background-color: #fbfbfb;
          font-size: 13px;

          &:focus {
            outline: none;
          }
          ::placeholder {
            font-family: $font-Helvetica-thin;
          }
        }

        .search-icon {
          position: absolute;
          left: 12px;
          opacity: 0.8;
          width: 13px;
        }
      }
    }

    .notifications-list {
      max-height: 400px;
      overflow-y: auto;

      .notification-item {
        padding: 12px;
        border-bottom: 1px solid #eee;
        transition: background-color 0.3s ease;

        // Highlight unread notifications
        &.unread {
          background-color: #f5f7fa;
        }

        .title {
          display: flex;
          align-items: center;

          .unread-dot {
            width: 8px;
            height: 8px;
            background-color: #21396d;
            border-radius: 50%;
            margin-right: 8px;
          }

          .read-dot {
            width: 8px;
            height: 8px;
            background-color: #21396d;
            border-radius: 50%;
            margin-right: 8px;
            visibility: hidden;
          }

          h4 {
            margin: 0;
            font-size: 14px;
            position: relative;
            left: 2px;
          }
        }

        .notification-message {
          font-size: 12px;
          color: $color-black;
          font-family: $font-Helvetica-thin;
          padding: 0px 0px 0px 19px;
          line-height: 18px;
        }

        .footer {
          font-size: 13px;
          color: $notification-txt-frm;
          margin-left: 20px;
          font-family: $font-Helvetica-thin;
          line-height: 18px;
          margin-top: 5px;

          .footer-text {
            color: $color-black;
            padding: 3px;
            font-size: 13px;

            .fw_notify {
              font-family: $font-Helvetica-medium;
            }
          }
        }
      }
    }

    .footer-link {
      text-align: center;
      padding: 12px;
      border-top: 1px solid #eee;

      .see-all {
        color: #21396d;
        font-size: 14px;

        font-family: $font-Helvetica-medium;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}
.cdk-global-overlay-wrapper {
  overflow-y: auto;
}

.mdc-text-field--focused {
  border: 1.5px solid $color-black;
  border-radius: 0px !important;
}
.start_end_date .mdc-text-field--focused {
  border: 0px solid $color-black !important;
  border-radius: 0px !important;
}
.login_ryt_usp .mat-mdc-input-element.ng-dirty {
  position: relative;
  // top: -4px !important;
}
.login_left_usp {
  background: url(../src/assets/images/common/login_page_usp/bg-login-left.png);
  background-size: 100%;
}
.total_records {
  color: $records-txt-clr;
}
custom-table .mdc-checkbox__background {
  top: -10px !important;
}
tbody {
  vertical-align: middle !important;
}
input:-webkit-autofill,
input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px $color-white inset;
}
.forecast-gap {
  padding-top: 25px !important;
}

.is-dialogue-form {
  padding: 0 1rem;
}

// Below style for applicable only for tax stamp create & edit
// .stamp-add-edit {
//   .padding_section {
//     app-dynamic-form-field {
//       .row {
//         display: grid !important;
//         grid-template-columns: 25% 25% 25% 25%;
//         grid-row-gap: 20px;

//         & > div {
//           width: 100% !important;
//         }

//         div:nth-child(2) {
//           grid-column: 1/3;
//         }
//         div:nth-child(3) {
//           grid-row: 2/3;
//           grid-column: 1/3;
//         }
//         div:nth-child(4) {
//           grid-row: 2/3;
//           grid-column: 3/4;
//         }
//         div:nth-child(5) {
//           grid-row: 2/3;
//           grid-column: 4/5;
//         }
//       }
//     }
//   }
// }
// Above style for applicable only for tax stamp create & edit

.update-invoice-status {
  .disable-dynamic-form {
    border: none !important;
  }
}
.column-search input[col-name="order_status_lbl"] {
  top: 1px !important;
}
.border_row {
  border-right: 1px solid $table-border;
  border-top: 1px solid $table-border;
  background: $color-white;
}
.no_forcast_data {
  color: $color-black;
  font-family: $font-Helvetica-regular;
  margin: 10px 0px;
  font-size: 14px;
}
.no_records_data {
  margin: 10px 0px;
}

.payment_total {
  color: $text-default-red;
}
.border_row {
  border-right: 1px solid $table-border;
  border-top: 1px solid $table-border;
}
.border_row .view-column-cell {
  border-left: 1px solid $table-border;
  border-bottom: 1px solid $table-border;
  padding: 5px 10px;
}
.d_flex_mail {
  display: flex;
  color: $color-black;
  gap: 2px;
}
.label_sts {
  color: $color-black;
  font-family: $font-Helvetica-thin;
  font-size: 14px;
}
.reject_payment {
  margin-top: 15px;
  padding: 10px 20px;
  border-top: 2px dashed $table-border;
}
.dashed_clr {
  color: $contact-border-clr;
}
.d_flx_num {
  display: flex;
  align-items: center;
  gap: 5px;
}
.sts_failed {
  background-color: $success-failed-clr;
  color: $color-white;
  padding: 2px 5px;
  font-size: 11px;
}
.sts_succeed {
  background-color: $color-light-greenish;
  color: $color-black;
  padding: 2px 5px;
  font-size: 11px;
}
.failure_popup {
  background-color: $success-failed-clr !important;
  h2 {
    color: $color-white;
  }
}
.payment-bg {
  background: #f9f8f6 !important;
}
.view-invoice-icon {
  top: -2px;
  position: relative;
  left: -5px;
}
.view_header {
  justify-content: space-between;
  align-items: center;
}
/* Scrollbar styling for the table (if needed) */
table::-webkit-scrollbar {
  height: 8px; /* Horizontal scrollbar height */
  width: 8px; /* Vertical scrollbar width */
}

table::-webkit-scrollbar-thumb {
  background-color: red; /* Thumb color */
  border-radius: 4px; /* Rounded scrollbar */
}

table::-webkit-scrollbar-track {
  background-color: $color-black; /* Track color */
}
.disable-dynamic-form .no_records_data {
  opacity: 0.3;
}
.custom_phonenumber .mat-mdc-form-field {
  flex-grow: 1;
}
.site_form .section-card:first-child .tin_txt {
  margin-bottom: 20px;
}

.table_border_td_none tbody[ng-reflect-name="user_info"] td,
.table_border_td_none tbody[ng-reflect-name="product_details"] td,
.table_border_td_none tbody[ng-reflect-name="supplier_info"] td {
  padding: 2px 8px 2px 8px !important;
}

.fc .fc-button-primary {
  background-color: $primary-txt-clr !important;
  border-radius: 0px;
}

.fc-today-button .fc-button .fc-button-primary {
  background-color: none !important;
}
.fc .fc-toolbar-title {
  color: #13316e;
  font-family: $font-Helvetica-medium;
  font-size: 20px;
}
.calender_gap .fc-prev-button {
  margin-right: 0.5px;
}

.fc-col-header-cell-cushion {
  text-decoration: none;
  color: #0b2036;
  font-family: $font-Helvetica-thin;
  font-size: 12px;
  padding: 8px 4px !important;
}

.fc-daygrid-day-top {
  a {
    color: #000;
    text-decoration: none;
  }
}

.fc-today-button,
.fc-prev-button,
.fc-next-button,
.fc-button {
  border: none !important;
  box-shadow: none !important;
}

.calender_gap .fc-daygrid-day-number {
  color: #0b2036 !important;
  padding: 10px;
}

.fc .fc-button.fc-today-button {
  font-size: 15px;
  font-family: $font-Helvetica-medium !important;
  background-color: transparent !important;
  border: none !important;
  color: $primary-txt-clr;
  box-shadow: none !important;
}

:host ::ng-deep .fc .fc-button.fc-today-button {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}

.fc-button-group .fc-dayGridMonth-button,
.fc-button-group .fc-timeGridWeek-button,
.fc-button-group .fc-timeGridDay-button {
  background-color: transparent !important;
  border: 1px solid $primary-txt-clr !important;
  color: $color-black !important;
  box-shadow: none !important;
  padding: 10px;
  width: 86px;
  height: 40px;
}
.fc-button-group .fc-dayGridMonth-button.fc-button-active,
.fc-button-group .fc-timeGridWeek-button.fc-button-active,
.fc-button-group .fc-timeGridDay-button.fc-button-active {
  background-color: $primary-txt-clr !important;
  border: 1px solid $primary-txt-clr !important;
  color: $color-white !important;
  // /padding: 10px;
  width: 80px;
  height: 40px;
}

.fc-direction-ltr .fc-button-group > .fc-button:not(:last-child) {
  border-right: 2px solid #ffffff5c !important;
}
.fc-daygrid-block-event .fc-event-time div,
.fc-daygrid-block-event .fc-event-title div {
  color: $color-white !important;
}
.fc-daygrid-event {
  border-radius: 0px !important;
}
.fc .fc-daygrid-day.fc-day-today {
  background-color: #c6d5fe !important;
}
.fc-daygrid-day-bottom {
  a {
    text-decoration: none !important;
  }
}
.product-status {
  .active-inactive {
    margin: 9px 0px 0px -10px !important;
  }
}
.fc-daygrid-event-harness {
  color: $color-white !important;
  margin-bottom: 3px;
}
.canceled-event {
  position: relative;
  text-decoration: line-through;
  text-decoration-color: $text-default-red !important;
  text-decoration-thickness: 2px;
  text-decoration-skip-ink: none;
  color: $color-white !important;
}

.fc-h-event .fc-event-main {
  color: $color-white;
  padding-left: 10px;
}
.fc-h-event .fc-event-main div {
  color: $color-white;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 165px;
}
table {
  border-collapse: collapse !important;
  border-spacing: 0px;
}
.custom-modal .file-list-container .absolute-container {
  position: absolute;
  left: 1rem !important;
  width: calc(100% - 2rem) !important;
}
.calender_gap .fc-dayGridMonth-view {
  overflow: scroll;
}
.calender_gap .fc-dayGridMonth-view::-webkit-scrollbar {
  display: none;
}
.calender_gap .fc-scrollgrid {
  width: 100%;
}
.mat-mdc-dialog-surface::-webkit-scrollbar {
  display: none;
}

@media (max-width: 1300px) {
  // .stamp-add-edit {
  //   .padding_section {
  //     app-dynamic-form-field {
  //       .row {
  //         display: grid !important;
  //         grid-template-columns: 25% 25% 30% 20%;
  //         grid-row-gap: 20px;
  //       }
  //     }
  //   }
  // }
  .padding_setup .mat-step-text-label {
    max-width: 125px !important;
    line-height: 15px;
    white-space: normal !important;
    word-break: break-all !important;
  }
}

@media (max-width: 1200px) {
  .padding_setup .mat-step-text-label {
    max-width: 170px !important;
  }
  .calender_gap .fc .fc-toolbar {
    align-items: center;
    gap: 30px;
    display: flex;
    justify-content: normal;
  }
  // .stamp-add-edit {
  //   .padding_section {
  //     app-dynamic-form-field {
  //       .row {
  //         display: flex !important;

  //         grid-row-gap: 0px;
  //         & > div {
  //           width: 50% !important;
  //         }
  //       }
  //     }
  //   }
  // }
}
@media (max-width: 768px) {
  .calender_gap .fc .fc-toolbar {
    align-items: start;
    flex-direction: column;
    gap: 15px;
    display: flex;
  }

  .margin_calender {
    margin: 15px !important;
  }
  // .stamp-add-edit {
  //   .padding_section {
  //     app-dynamic-form-field {
  //       .row {
  //         display: flex !important;

  //         grid-row-gap: 0px;
  //         & > div {
  //           width: 100% !important;
  //         }
  //       }
  //     }
  //   }
  // }
  .pt_users {
    padding-top: 15px !important;
  }
}

.inspection-form {
  .title_width_registration {
    font-size: 16px !important;
    font-weight: 500 !important;
    font-family: $font-Helvetica-medium;
  }
}

app-master-crud-form .multiselect-dropdown .dropdown-list {
  overflow: hidden;
}

// ng select UI changes.........................

.material
  .custom-ng-select.ng-select.ng-select-focused
  .ng-select-container
  .ng-value-container
  .ng-placeholder,
.material .custom-ng-select.ng-select .ng-has-value .ng-placeholder,
.ng-select.ng-select-opened .ng-placeholder {
  transform: translateY(-1.78125em) scale(1) perspective(100px)
    translateZ(0.001px);
  color: #757575;
}

.ng-select.ng-select-focused .ng-select-container.ng-appearance-outline:after,
.ng-select.ng-select-focused
  .ng-select-container.ng-appearance-outline:hover:after {
  border: 1px solid !important;
}

.default .custom-ng-select.ng-select {
  .ng-select-container {
    border-radius: 0px !important;
  }
  .ng-placeholder {
    font-family: $font-Helvetica-regular !important;
    top: 7px !important;
    color: #000 !important;
  }
  .ng-select-container .ng-value-container {
    align-items: center;
    min-width: 130px;
  }
  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(0, 0, 0, 0.04);
  }

  .ng-dropdown-panel {
    top: auto !important;
  }

  .ng-clear-wrapper {
    top: 2px;
  }

  .selected-item {
    border: 1px solid $color-primary-blue !important;
    margin-right: 4px;
    background: $color-primary-blue !important;
    padding: 0 5px;
    color: $color-white;
    border-radius: 0px !important;
    float: left;
    max-width: 100px;
    .selected-txt {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .ng-arrow-wrapper .ng-arrow {
    background: url("assets/images/common/icons/dropdown_arow_down.svg")
      no-repeat;
    width: 12px;
    height: 12px;
    background-position: center;
    border: none !important;
    top: 1px;
  }
  .ng-value-container .ng-value {
    z-index: 9;
  }
}
.material .custom-ng-select.ng-select .ng-dropdown-panel {
  bottom: 20px;
}

.material .custom-ng-select.ng-select {
  .ng-select-container .ng-value-container {
    align-items: center;
  }
  .ng-placeholder {
    font-family: $font-Helvetica-regular !important;
  }
  .ng-select-container.ng-appearance-outline {
    min-height: 55px !important;
    font-size: 14px !important;
    text-transform: capitalize;
    padding: 0 12px;
  }

  .ng-select-container .ng-value-container .ng-input {
    bottom: auto !important;
    padding: 0 14px;
  }
  .ng-select-container.ng-appearance-outline:after {
    border: 1px solid #ddd;
    border-radius: 0;
  }

  .ng-select-container {
    align-items: center;
  }

  .ng-arrow-wrapper .ng-arrow {
    background: url("assets/images/common/icons/dropdown_arow_down.svg")
      no-repeat;
    width: 12px;
    height: 12px;
    background-position: bottom;
    border: none !important;
  }

  .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
    background: rgba(0, 0, 0, 0.04);
  }

  .ng-dropdown-panel {
    top: auto !important;
  }

  .selected-item {
    border: 1px solid $color-primary-blue !important;
    margin-right: 4px;
    background: $color-primary-blue !important;
    padding: 0 5px;
    color: $color-white;
    border-radius: 0px !important;
    float: left;
    max-width: 100px;
    .selected-txt {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .ng-value-container .ng-value {
    z-index: 9;
  }
}

// .........................ng select UI changes

.info-circle-img {
  width: 20px;
  height: 20px;
  padding-left: 5px;
}
mat-tooltip-component {
  .mat-mdc-tooltip-surface {
    min-width: unset !important;
    max-width: unset !important;
    border-radius: 0px;
    padding-bottom: 5px;
    color: $color-white;
    font-family: $font-Helvetica-thin !important;
    background-color: #14161c !important;
  }
}
.custom-tooltip-surface {
  position: relative;
  z-index: 9999;
  margin-top: 5px !important;
}

.read-checkbox .mdc-checkbox__background {
  width: 14px !important;
  height: 14px !important;
  top: -18px !important;
  left: -16px !important;
}
.read-checkbox .mdc-checkbox__checkmark {
  width: 76% !important;
  top: 1px !important;

  left: 1.5px !important;
}
.read-checkbox .mat-internal-form-field {
  display: unset;
}
.verified-scp-form {
  background: $entire-bg;
}
.major-footer {
  z-index: 9999;
}

.file_fs {
  font-size: 12px;
  position: relative;
  top: 0.5px;
}
.with-top-left-border-gap {
  margin: 5px 0px 15px !important;
}
.id_txt {
  color: $text-blue-clr;
}
.pointerEventNone-ng-select,
.disable-registration-form {
  pointer-events: none !important;
  .ng-invalid.ng-touched .ng-select-container.ng-appearance-outline:after {
    border: 1px solid red !important;
  }
  .ng-select-container.ng-appearance-outline:after {
    background: #f6f8f9 0% 0% no-repeat padding-box !important;
  }
  .ng-placeholder {
    background: #f6f8f9 !important;
  }
}
.success-text {
  font-family: $font-Helvetica-medium;
}
.success-text::first-letter {
  text-transform: capitalize;
}
.success-bodycontent {
  font-family: $font-Helvetica-medium;
  padding-left: 20px;
  padding-right: 20px;

  .success-bodycontent::first-letter {
    text-transform: capitalize;
  }
}
.id_data {
  color: $text-blue-clr;
}
.mat-pseudo-checkbox {
  border-radius: 0px !important;
}
.mat-pseudo-checkbox-full {
  border-width: 1px !important;
  border-color: $color-boulder-grey !important;
}
.mdc-list-item__primary-text {
  font-family: $font-Helvetica-regular !important;
  color: $color-black;
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-checked {
  background-color: $text-blue-clr !important;
}
.mdc-text-field--disabled mat-label {
  background-color: $placeholder-disabled !important;
}

custom-ng-select[ng-reflect-input-select-disable-state="false"]
  .ng-touched.ng-invalid
  .ng-appearance-outline:after {
  border: 1px solid $border-ryt-login;
}
custom-ng-select[ng-reflect-input-select-disable-state="false"]
  .ng-untouched.ng-invalid
  .ng-appearance-outline:after {
  border: 1px solid #ddd;
}
custom-ng-select[ng-reflect-input-select-disable-state="false"]
  .ng-touched.ng-valid
  .ng-appearance-outline:after {
  border: 1px solid #ddd;
}
.footer_fixed {
  z-index: 999;
}
input[type="checkbox"] {
  -webkit-appearance: none; /* Remove default styling in WebKit-based browsers */
  appearance: none;
  width: 17px;
  height: 17px;
  border: 1.5px solid $color-boulder-grey !important;
  border-radius: 0 !important;
  cursor: pointer;
  background-color: $color-white; /* Default background color */
  position: relative;
}

/* Style the checkbox when checked */
input[type="checkbox"]:checked {
  background-color: $color-light-green !important;
  border-color: $color-light-green !important;
}

/* Custom checkmark styling */
input[type="checkbox"]:checked::after {
  content: ""; /* This makes the checkmark visible */
  position: absolute;
  top: 1px;
  left: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}
.ng-optgroup,
.nt-optgroup[aria-selected="true"] {
  font-family: $font-Helvetica-medium !important;
  color: $color-black !important;
  font-weight: 400 !important;
}
.font_checkox span {
  font-family: $font-Helvetica-regular !important;
}
.ng-optgroup .ng-select input {
  border-top: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}

.download-template {
  a {
    font-weight: 500;
    text-decoration: none;
  }
}
.geo_config .title-card {
  margin-bottom: 20px !important;
}
.config_acnt .title-card {
  margin-bottom: 12px !important;
}
.geo_config .status-label,
.geo_config .config_acnt {
  margin-bottom: 5px;
  font-size: 13px;
}
.geo_config .pull-right {
  margin-top: -14px;
  margin-right: 10px;
}
.download-template mat-label {
  font-size: 13px;
  font-family: $font-Helvetica-regular;
}
.download-template span {
  font-family: $font-Helvetica-medium;
}

.mat-horizontal-stepper-content[aria-labelledby="cdk-step-label-0-4"]
  .status-label {
  margin-bottom: 5px;
  font-size: 13px;
}
.header-logo {
  width: 32px !important;
  img {
    width: 100%;
  }
}
.number-field {
  text-align: right !important;
}
.name_details_flx {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  font-family: $font-Helvetica-regular;
  padding: 13px 20px;
}
.billing-summary-content,
.payment-detail-content {
  overflow-x: scroll;
}
.billing-summary-content th,
.billing-summary-content td,
.payment-detail-content th,
.payment-detail-content td {
  white-space: nowrap;
}
.custom-tooltip {
  max-width: 300px;
  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
}
.terms-list {
  margin: 0px 20px;
}

.required-field {
  color: #d63322;
}
sup {
  display: none;
  top: 0 !important;
  font-size: 15px !important;
}
.mdc-floating-label--float-above sup {
  display: inline-block;
  font-size: 18px !important;
}

.color-orange {
  color: orange;
}
.pos_tooltip_relative {
  position: relative;
}
.pos_tooltip_abs {
  position: absolute;
  background-color: $color-black;
  color: $color-white;
  z-index: 999999999999999999999;
  top: -44px;
  left: -190px;
  padding: 5px 10px;
  opacity: 0;
  visibility: hidden;
}
.pos_tooltip_relative:hover .pos_tooltip_abs {
  opacity: 1;
  visibility: visible;
}
.popup_item {
  display: flex;
  color: $color-white;
  font-size: 13px;
  font-family: $font-Helvetica-thin;
}
.data_forecast {
  font-size: 14px;
  font-family: $font-Helvetica-thin;
}
.compare_div {
  color: $color-white;
  text-align: center;
  position: relative;
  font-family: $font-Helvetica-thin;
  font-size: 12px;
}
.compare_div::before,
.compare_div::after {
  content: "";
  position: absolute;
  background-color: $bg-white;
  width: 50%;
  top: 10px;
  height: 0.1px;
  display: block;
}
.compare_div::before {
  left: -10px;
}
.compare_div::after {
  content: "";
  right: -10px;
}
table td div.icn_tooltip_hidden {
  overflow: unset;
}

.dropdown-menu_export {
  position: absolute;
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 0.5rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  top: 45px;

  button {
    background: none;
    border: none;
    padding: 0.5rem 1rem;
    text-align: left;
    width: 100%;
    white-space: nowrap;

    cursor: pointer;

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
.pos_relative_export,
.pos_export {
  position: relative;
}
.custom-action-changes {
  padding: 10px;
  background-color: $theme-color-primary;
  color: $color-white;
  gap: 10px;
  cursor: pointer;
  justify-content: space-between;
  min-width: 137px;
}
.border_arw {
  position: relative;
}
.border_arw::before {
  position: absolute;
  content: "";
  width: 0.5px;
  height: 41px;
  top: -10px;
  left: -16px;
}
.action_btn {
  border: 0.5px solid #f0f0f0;
  font-family: $font-Helvetica-regular;
  font-size: 15px !important;
  span {
    font-size: 15px !important;
  }
}
.action_btn:hover,
.action_btn:focus-visible {
  background-color: $color-white !important;
}
.removed-highlightTxt {
  color: red;
  text-decoration-line: line-through;
}
.new-highlightTxt {
  color: green;
}

.revision-history {
  .more-icon {
    float: left;
    margin-right: 10px;
  }
  .custom-table-container
    .custom-table
    .custom-table-body
    .custom-table-body-row
    .custom-table-body-cell {
    overflow: auto;
    white-space: pre-wrap;
  }
}
.spinner_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.multiselect_div .ng-placeholder {
  position: relative;
}

.multiselect_div .ng-select-opened .ng-placeholder::after,
.multiselect_div .pointerEventNone-ng-select .ng-valid .ng-placeholder::after,
.multiselect_div .ng-has-value .ng-placeholder::after {
  position: absolute;
  content: "*";
  color: $text-default-red;
  top: -2px;
  right: -4px;
  visibility: visible;
}
.multiselect_div .ng-touched.ng-invalid .ng-appearance-outline:after {
  border: 1px solid $text-default-red !important;
}
.global-spinner-style {
  height: 4rem;
}
.stamp_preview {
  display: flex;
  gap: 10px;
}
.overlay {
  background: rgba(0, 0, 0, 0.064); /* Semi-transparent black */
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  z-index: 9999; /* Ensure it stays above other content */
}
.custom-table tr:nth-child(odd) .bg-white {
  background-color: #fff !important;
}
.spinner_center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw !important;
  padding: 20px 0px;
}

.loader {
  width: 30px;
  height: 30px;
  display: inline-block;
  position: relative;
  border-width: 2px 1px 2px 1px;
  border-style: solid dotted solid dotted;
  border-color: #de3500 rgba(255, 255, 255, 0.3) $theme-color-popup
    rgba(151, 107, 93, 0.3);
  border-radius: 50%;
  box-sizing: border-box;
  animation: 1s rotate linear infinite;
}
.loader:before,
.loader:after {
  content: "";
  top: 0;
  left: 0;
  position: absolute;
  border: 6px solid transparent;
  border-bottom-color: $theme-color-popup;
  transform: translate(-7px, 10px) rotate(-35deg);
}
.loader:after {
  border-color: #de3500 #0000 #0000 #0000;
  transform: translate(25px, 3px) rotate(-35deg);
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

.border-left {
  border-left: var(--bs-border-width) var(--bs-border-style)
    var(--bs-border-color) !important;
}

.ng-select.ng-select-opened .ng-select-container {
  z-index: unset !important;
}
.ng-dropdown-panel {
  z-index: 990 !important;
}

.custom-tooltip {
  position: absolute;
  top: 100%;
  background-color: #ffffff;
  border: 1px solid #cccccc;
  left: 100px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  max-width: 350px;
  font-size: 14px;
}
.tooltip-header {
  background-color: #f6f7f9;
  padding: 10px;
  display: flex;
  justify-content: space-between;

  h3 {
    margin-bottom: 0px;
    color: #000;
    font-size: 14px;
    font-family: $font-Helvetica-medium;
  }
  p {
    color: #869dae;
    font-size: 12px;
    font-family: $font-Helvetica-regular;
    margin-bottom: 0px;
  }
}
.tooltip-field1 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
  gap: 35px;
}
.field-name {
  font-size: 12px;
  font-family: $font-Helvetica-regular;
  color: #869dae;
}
.field-value {
  font-size: 12px;
  font-family: $font-Helvetica-regular;
  color: #000000;

  a {
    cursor: none;
  }
}
.tooltip-field2 {
  padding: 10px;
  column-gap: 35px;
}
.border-field {
  border-top: 1px dashed;
  border-bottom: 1px dashed;
  border-color: #bdbdbd;
}

.multi-group-active-inactive {
  padding: 5px 0px;
  margin-top: 2px;
}

.dashedBorder {
  border: 1px dashed #bdbdbd;
}

.outlineBorder {
  border: 1px solid #bdbdbd;
}

.createAnotherChkbox {
  position: relative;
}

.createAnotherChkbox .d-flex {
  display: inline !important;
  position: relative;
}

.createAnotherChkbox .margin_scp {
  top: 10px;
}

.mr-10 {
  margin-right: 10px;
}

.btn_default_outline_blue {
  background-color: $color-white !important;
  color: $color-primary-blue !important;
  outline: 0;
  border: 1px solid $color-primary-blue !important;
  font-family: $font-Helvetica-regular !important;
  padding: 22px 27px !important;
  border-radius: 0px !important;
  box-shadow: none !important;
}

span.api-highlighted {
  background: #ddd;
  padding: 0 5px;
  border-radius: 4px;
  color: #000;
}

.scp-delivery-details-api table th,
.scp-delivery-details-api table td {
  border: 1px solid #ddd;
}
.inspection_align app-dynamic-form-field .row {
  align-items: baseline !important;
}

.inspection_align app-dynamic-form-field .ng-option {
  text-overflow: unset !important;
  overflow: unset !important;
  margin-bottom: 5px !important;
  padding-top: 5px !important;
  padding-bottom: 8px !important;
}
.font_checkbox_grid:first-child {
  margin-top: 10px;
}
.inspection_align app-dynamic-form-field .font_checkbox_grid span {
  white-space: break-spaces;
}

span.blue-highlight {
  padding: 10px 0;
  float: left;
  color: #1e5af9;
  font-weight: 600;
  cursor: pointer;
}

.slab-dialogue {
  .dynamic-form-dialogue {
    display: grid;
    grid-template-rows: repeat(3, 0.5fr);
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
    > div {
      width: 100% !important;
    }
    > div:nth-child(1) {
      grid-column: 1/3;
    }
    > div:nth-child(2) {
      grid-column: 3/4;
    }
    > div:nth-child(3) {
      grid-row: 2 / 3;
      grid-column: 1 / 4;
      border: 1px dashed #ddd;
      height: 1px;
      margin: 0 !important;
      padding: 0 !important;
    }
    > div:nth-child(7) {
      grid-row: 4 / 5;
      grid-column: 1 / 4;
      display: flex;
      > div {
        width: 100% !important;
        display: grid;
        .row {
          display: grid !important;
          grid-template-columns: repeat(3, 1fr);
          > div {
            width: 100% !important;
          }
        }
      }
    }
  }
}
.chart-field .mat-mdc-form-field-infix {
  padding-top: 5px !important;
  padding-right: 10px;
}
.chart-field .mdc-text-field--filled:not(.mdc-text-field--disabled) {
  background-color: unset !important;
  border: 1px solid $color-black !important;
  border-radius: 0px !important;
}
.discount-range {
  padding: 1px;
}

.discount-range {
  .discount-pop {
    display: grid;
    grid-template-rows: repeat(3, auto);
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
  }
}

.chart-field {
  .mat-mdc-select-trigger {
    position: relative;
    top: 12px !important;
  }
}

.discount-popover-container {
  position: relative;
  display: inline-block;

  .popover-btn {
    border: none;
    color: white;
    cursor: pointer;
  }

  .popover-content {
    position: absolute;
    top: 0%;
    right: 100%;
    transform: translateX(0%);
    background-color: #333;
    color: white;
    padding: 10px;
    white-space: nowrap;
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  &:hover .popover-content {
    visibility: visible;
    opacity: 1;
  }
}
.attachment_clr {
  text-decoration: none;
}
.order_list .document_status {
  min-width: 100px;
}
.border_all_row{
  border-top: 1px solid $table-border;
    border-left: 1px solid $table-border;
}
.border_view_cmn{
  border-bottom: 1px solid $table-border;
  border-right: 1px solid $table-border;
  padding: 5px;
}
.custom-table-body-cell.column-search-td .mat-mdc-text-field-wrapper {
  padding-left: 0px !important;
}

.packageForm .package_container {
  position: relative;
  top: -1.5rem;
  left: -2.4rem;
}

.packageForm .package_container .add-more-table-btn {
  left: 3rem;
}

@media (min-width: 1027px) {
  .packageForm .primary_package_container .field-group-delete-container {
    right: 32%;
  }

  .packageForm .package_container .field-group-delete-container {
    position: relative;
    top: 0.75rem;
  }

  .packageForm .primary_package_container .dynamic-form-dialogue {
    position: inherit !important;
  }

  .packageForm.package_wrapper {
    margin-top: -26px !important;
  }
}

.package_wrapper .column_input_gap {
  margin-bottom: 10px;
}

.packageForm.primary_package_wrapper {
  border-top: 1px solid #ccc;
  margin-top: 1rem !important;
  position: relative;
}
.primary_package_wrapper::before,
.primary_package_wrapper::after {
  content: " ";
  position: absolute;
  top: -1px;
  width: 32px;
  height: 1px;
  background-color: $color-silver;
}
.primary_package_wrapper::before {
  left: -30px;
}

.primary_package_wrapper::after {
  right: -30px;
}
.overflow_hidden_stamp > .column_input_gap:nth-child(-n+4) {
  margin-top: 10px;
}
