@import "variable";
@import "mixin";

/* Font Family */
//Helvetica Now Text



@font-face {
  font-family: $font-Helvetica-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-700 !important;
  src: url("../../assets/fonts/HelveticaNow-Bold.ttf") format("truetype");

}





@font-face {
  font-family: $font-Helvetica-thin;
  font-style: $font-style-normal !important;
  font-weight: $fw-200 !important;
  src: url("../../assets/fonts/HelveticaNow-Thin.ttf") format("truetype");
}

@font-face {
  font-family: $font-Helvetica-medium;
  font-style: $font-style-normal !important;
  font-weight: $fw-500 !important;
  src: url("../../assets/fonts/HelveticaNow-Medium.ttf") format("truetype");
  
}



@font-face {
  font-family: $font-Helvetica-regular;

  font-style: $font-style-normal !important;
  font-weight: $fw-400 !important;
  src: url("../../assets/fonts/HelveticaNow-Regular.ttf") format("truetype");
}





//Sora

@font-face {
  font-family: $font-Sora-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-700 !important;
  src: url("/assets/fonts/Sora-Bold.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-extra-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-800 !important;
  src: url("/assets/fonts/Sora-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-extra-light;
  font-style: $font-style-normal !important;
  font-weight: $fw-200 !important;
  src: url("/assets/fonts/Sora-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-light;
  font-style: $font-style-normal !important;
  font-weight: $fw-300 !important;
  src: url("/assets/fonts/Sora-Light.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-medium;
  font-style: $font-style-normal !important;
  font-weight: $fw-500 !important;
  src: url("/assets/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-regular;
  font-style: $font-style-normal !important;
  font-weight: $fw-400 !important;
  src: url("/assets/fonts/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $font-Sora-semi-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-600 !important;
  src: url("/assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}

// Manrope
@font-face {
  font-family: $font-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-700 !important;
  src: url("/assets/fonts/Manrope-Bold.ttf") format("truetype");
}

@font-face {
  font-family: $font-extra-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-800 !important;
  src: url("/assets/fonts/Manrope-ExtraBold.ttf") format("truetype");
}

@font-face {
  font-family: $font-extra-light;
  font-style: $font-style-normal !important;
  font-weight: $fw-200 !important;
  src: url("/assets/fonts/Manrope-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: $font-light;
  font-style: $font-style-normal !important;
  font-weight: $fw-300 !important;
  src: url("/assets/fonts/Manrope-Light.ttf") format("truetype");
}

@font-face {
  font-family: $font-medium;
  font-style: $font-style-normal !important;
  font-weight: $fw-500 !important;
  src: url("/assets/fonts/Manrope-Medium.ttf") format("truetype");
}

@font-face {
  font-family: $font-regular;
  font-style: $font-style-normal !important;
  font-weight: $fw-400 !important;
  src: url("/assets/fonts/Manrope-Regular.ttf") format("truetype");
}

@font-face {
  font-family: $font-semi-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-600 !important;
  src: url("/assets/fonts/Manrope-SemiBold.ttf") format("truetype");
}
//  Barlow font family

@font-face {
  font-family: $font-barlow-bold;
  font-style: $font-style-normal !important;
  font-weight: $fw-900 !important;
  src: url("/assets/fonts/BarlowCondensed-Bold.ttf") format("truetype");
}

/* font size class list */

.fs-10 {
  font-size: $f-10 !important;
}
.fs-12 {
  font-size: $f-12 !important;
}

.fs-14 {
  font-size: $f-14 !important;
}

.fs-15 {
  font-size: $f-15 !important;
}

.fs-16 {
  font-size: $f-16 !important;
}

.fs-18 {
  font-size: $f-18 !important;
}

.fs-20 {
  font-size: $f-20 !important;
}

.fs-24 {
  font-size: $f-24 !important;
}

.fs-25 {
  font-size: $f-25 !important;
}

.f-27 {
  font-size: $f-27 !important;
}

.fs-32 {
  font-size: $f-32 !important;
}

.f-65 {
  font-size: $f-65 !important;
}

/* font family class list */

.fa-custom-bold {
  font-family: $font-Sora-bold !important;
}

.fa-custom-semi-bold {
  font-family: $font-Sora-semi-bold !important;
}

.fa-custom-ex-bold {
  font-family: $font-Sora-extra-bold !important;
}

.fa-custom-light {
  font-family: $font-Sora-light !important;
}

.fa-custom-ex-light {
  font-family: $font-Sora-extra-light !important;
}

.fa-custom-medium {
  font-family: $font-Sora-medium !important;
}

.fa-custom-regular {
  font-family: $font-Sora-regular !important;
}

/* font weight class list */

.fw-100 {
  font-weight: $fw-100 !important;
}

.fw-200 {
  font-weight: $fw-200 !important;
}

.fw-300 {
  font-weight: $fw-300 !important;
}

.fw-400 {
  font-weight: $fw-400 !important;
}

.fw-500 {
  font-weight: $fw-500 !important;
}

.fw-600 {
  font-weight: $fw-600 !important;
}

.fw-700 {
  font-weight: $fw-700 !important;
}

.fw-800 {
  font-weight: $fw-800 !important;
}

.fw-900 {
  font-weight: $fw-900 !important;
}

/* font color class list */

.fc-black {
  color: $color-black !important;
}

.fs-blue {
  color: $color-shade-of-blue !important;
}
